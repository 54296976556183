import { Card } from 'reactstrap'
import IdeaStatusElement from '../IdeaStatusElement/IdeaStatusElement'

function IdeaStatusViewer({ ideasStatus }) {
    return (
        <>
            {ideasStatus?.map(idea =>
                <Card className="bg-secondary border-0 p-4 d-flex flex-row justify-content-between" key={idea?._id}>
                    <div className='d-flex flex-column justify-content-center'>
                        <div >
                            <h3>Idea ID</h3>
                            <h5> {idea?._id}</h5>
                        </div>
                        <div >
                            <h3>Idea Text</h3>
                            <h5> {idea?.ideaText}</h5>
                        </div>
                    </div>

                    {Object.entries(idea?.status).map((el, index) => {
                        if (/false/i.test(el[1]?.accepted) && /true/i.test(el[1]?.value)) {
                            return (
                                <div className='text-center' key={el[1]?.reasonOfDenial + index}>
                                    <h3>Reason of denial</h3>
                                    <h5>{el[1]?.reasonOfDenial}</h5>
                                </div>
                            )
                        } else {
                            return null
                        }
                    }
                    )}
                    <div className="right">
                        <IdeaStatusElement idea={idea?.status} />
                    </div>
                </Card>
            )}
        </>
    )
}

export default IdeaStatusViewer