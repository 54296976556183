/*eslint-disable*/
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardHeader, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import { deleteUser, getUsers } from "../../../store/slices/users/userActions";
import { usersDataTableColumns } from "../../../utils/Data";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const User = () => {
  const { t, i18n } = useTranslation();
  const users = useSelector((state) => state.user.users) || [];
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const deleteOne = async (id) => {
    if (user.userType !== "ADMIN") return;
    let result = await confirm({
      title: <>Warning</>,
      message: t("Are you sure you want to delete this?"),
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    if (result) dispatch(deleteUser(id));
  };
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <>
      <SimpleHeader name="Users" parentName="User management" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Users</h3>
              </CardHeader>

              <ToolkitProvider
                data={users}
                keyField="name"
                columns={[
                  ...usersDataTableColumns(t, i18n.language),
                  {
                    dataField: "_id",
                    text: "actions",
                    formatter: (cell, row, rowIndex, formatExtraData) =>
                      user?.userType === "ADMIN" ? (
                        <>
                          <Link
                            to={{
                              pathname: "/admin/editUser/" + cell,
                              state: { data: row },
                            }}
                            key={cell}
                            className="text-primary"
                          >
                            Edit
                          </Link>
                          <span
                            className="table-action table-action-delete"
                            id={cell}
                            onClick={() => deleteOne(cell)}
                          >
                            <i className="fas fa-trash" />
                          </span>
                        </>
                      ) : (
                        () => null
                      ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      hover={true}
                      tabIndexCell={true}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      keyField="index"
                      data={users?.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default User;
