import { createSlice, current } from "@reduxjs/toolkit";
import { addCompany, deleteCompany, editCompany, getCompanies, getCompanyNames } from "./companyActions";
// import { User } from "../../models/state.interface";
const initialState = {
  companiesNames: [],
  companies: []
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyNames.fulfilled, (state, action) => {
      state.companiesNames = action.payload;
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
    });
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      const newState = current(state).companies.filter(
        (company) => company._id !== action.payload
      );
      state.companies = newState;
    });
    builder.addCase(editCompany.fulfilled, (state, action) => {

      const newState = current(state).companies.map(
        (company) => {
          if (company._id === action.payload._id) { return (action.payload) } else { return company }
        }
      );
      state.companies = newState;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = companySlice.actions;

export default companySlice.reducer;
