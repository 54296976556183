import { createSlice, current } from "@reduxjs/toolkit";
import { addMiniBusinesses, deleteMiniBusinesses, editMiniBusinesses, getMiniBusinesses } from "./miniBusinessActions";
const initialState = {
    miniBusinesses: []
};

export const miniBusinessSlice = createSlice({
    name: "miniBusiness",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMiniBusinesses.fulfilled, (state, action) => {
            state.miniBusinesses = action.payload;
        });
        builder.addCase(addMiniBusinesses.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.miniBusinesses = newState;
        });
        builder.addCase(deleteMiniBusinesses.fulfilled, (state, action) => {
            const newState = current(state).miniBusinesses.filter(
                (miniBusiness) => miniBusiness._id !== action.payload
            );
            state.miniBusinesses = newState;
        });
        builder.addCase(editMiniBusinesses.fulfilled, (state, action) => {

            const newState = current(state).miniBusinesses.map(
                (miniBusiness) => {
                    if (miniBusiness._id === action.payload._id) { return (action.payload) } else { return miniBusiness }
                }
            );
            state.miniBusinesses = newState;
        });
    },
});

export default miniBusinessSlice.reducer;
