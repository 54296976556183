import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getDepartments = createAsyncThunk(
    "department/all",
    async () => {
        try {
            const response = await axios.get(API_URLS.Department.get);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const getDepartmentsById = createAsyncThunk(
    "department/all/Id",
    async (id) => {
        try {
            const response = await axios.get(API_URLS.Department.getAllById.replace('{Id}', id));
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const addDepartment = createAsyncThunk(
    "department/post",
    async (data) => {
        try {
            const token = localStorage.getItem("opexDashbaordToken");
            let requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await axios.post(API_URLS.Department.post, data, requestOptions);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const editDepartment = createAsyncThunk("department/edit", async (data) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        let requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Department.put.replace("{Id}", data._id);
        await axios.put(url, data, requestOptions);
        return data;
    } catch (e) {
        return e;
    }
});

const deleteDepartment = createAsyncThunk("department/delete", async (id) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Department.delete.replace("{Id}", id);
        await axios.delete(url, requestOptions);
        return id;
    } catch (e) {
        return e;
    }
});

export { addDepartment, deleteDepartment, getDepartments, editDepartment, getDepartmentsById };
