import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getEmployeeCodeSheets = createAsyncThunk(
  "EmployeeCodeSheet/all",
  async () => {
    try {
      const response = await axios.get(API_URLS.EmployeeCodeSheet.get);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const addEmployeeCodeSheet = createAsyncThunk(
  "EmployeeCodeSheet/post",
  async ({ data, alertFun, t }) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await axios.post(
        API_URLS.EmployeeCodeSheet.createMany,
        data,
        requestOptions
      );
      alertFun("success", t("Your file has been uploaded"));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return response.data;
    } catch (error) {
      alertFun("danger", t("An error occured please try again"));
      return error;
    }
  }
);
const editEmployeeCodeSheet = createAsyncThunk(
  "EmployeeCodeSheet/edit",
  async (data) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.EmployeeCodeSheet.put.replace("{Id}", data._id);
      await axios.put(url, data, requestOptions);
      return data;
    } catch (e) {
      return e;
    }
  }
);

const deleteEmployeeCodeSheet = createAsyncThunk(
  "EmployeeCodeSheet/delete",
  async (id) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.EmployeeCodeSheet.delete.replace("{Id}", id);
      await axios.delete(url, requestOptions);
      return id;
    } catch (e) {
      return e;
    }
  }
);

export {
  addEmployeeCodeSheet,
  deleteEmployeeCodeSheet,
  getEmployeeCodeSheets,
  editEmployeeCodeSheet,
};
