import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getFactories = createAsyncThunk(
    "factory/all",
    async () => {
        try {
            const response = await axios.get(API_URLS.Factory.get);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const getFactoriesById = createAsyncThunk(
    "factory/all/Id",
    async (id) => {
        try {
            const response = await axios.get(API_URLS.Factory.getAllById.replace('{Id}', id));
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const addFactory = createAsyncThunk(
    "factory/post",
    async (data) => {
        try {
            const token = localStorage.getItem("opexDashbaordToken");
            let requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await axios.post(API_URLS.Factory.post, data, requestOptions);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const editFactory = createAsyncThunk("factory/edit", async (data) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        let requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Factory.put.replace("{Id}", data._id);
        await axios.put(url, data, requestOptions);
        return data;
    } catch (e) {
        return e;
    }
});

const deleteFactory = createAsyncThunk("factory/delete", async (id) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Factory.delete.replace("{Id}", id);
        await axios.delete(url, requestOptions);
        return id;
    } catch (e) {
        return e;
    }
});

export { getFactories, addFactory, deleteFactory, editFactory, getFactoriesById };
