import { createSlice, current } from "@reduxjs/toolkit";
import {
  addRewardAndRecognition,
  deleteRewardAndRecognition,
  editRewardAndRecognition,
  getRewardAndRecognitions,
} from "./RewardAndRecognitionActions";
const initialState = {
  RewardAndRecognitions: [],
};

const RewardAndRecognitionSlice = createSlice({
  name: "RewardAndRecognition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRewardAndRecognitions.fulfilled, (state, action) => {
      state.RewardAndRecognitions = action.payload;
    });
    builder.addCase(addRewardAndRecognition.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.RewardAndRecognitions = newState;
    });
    builder.addCase(deleteRewardAndRecognition.fulfilled, (state, action) => {
      const newState = current(state).RewardAndRecognitions.filter(
        (AICMinibusiness) => AICMinibusiness._id !== action.payload
      );
      state.RewardAndRecognitions = newState;
    });
    builder.addCase(editRewardAndRecognition.fulfilled, (state, action) => {
      const newState = current(state).RewardAndRecognitions.map(
        (AICMinibusiness) => {
          if (AICMinibusiness._id === action.payload._id) {
            return action.payload;
          } else {
            return AICMinibusiness;
          }
        }
      );
      state.RewardAndRecognitions = newState;
    });
  },
});

export default RewardAndRecognitionSlice.reducer;
