import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getareaSections = createAsyncThunk("areaSection/all", async () => {
  try {
    const response = await axios.get(API_URLS.AreaSection.get);
    return response.data;
  } catch (error) {
    return error;
  }
});

const getareaSectionsById = createAsyncThunk(
  "areaSection/all/Id",
  async (id) => {
    try {
      const response = await axios.get(
        API_URLS.AreaSection.getAllById.replace("{Id}", id)
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const addareaSection = createAsyncThunk("areaSection/post", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await axios.post(
      API_URLS.AreaSection.post,
      data,
      requestOptions
    );
    return response.data;
  } catch (error) {
    return error;
  }
});
const editAreaSection = createAsyncThunk("areaSection/edit", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.AreaSection.put.replace("{Id}", data._id);
    await axios.put(url, data, requestOptions);
    return data;
  } catch (e) {
    return e;
  }
});

const deleteAreaSection = createAsyncThunk("areaSection/delete", async (id) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.AreaSection.delete.replace("{Id}", id);
    await axios.delete(url, requestOptions);
    return id;
  } catch (e) {
    return e;
  }
});

export {
  getareaSections,
  addareaSection,
  deleteAreaSection,
  editAreaSection,
  getareaSectionsById,
};
