import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getAICMinibusinesses = createAsyncThunk(
  "AICMinibusiness/all",
  async () => {
    try {
      const response = await axios.get(API_URLS.AICMiniBusiness.get);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getAICMinibusinessesById = createAsyncThunk(
  "AICMinibusiness/all/Id",
  async (id) => {
    try {
      const response = await axios.get(
        API_URLS.AICMiniBusiness.getAllById.replace("{Id}", id)
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const addAICMinibusiness = createAsyncThunk(
  "AICMinibusiness/post",
  async (data) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios.post(
        API_URLS.AICMiniBusiness.post,
        data,
        requestOptions
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
const editAICMinibusiness = createAsyncThunk(
  "AICMinibusiness/edit",
  async (data) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.AICMiniBusiness.put.replace("{Id}", data._id);
      await axios.put(url, data, requestOptions);
      return data;
    } catch (e) {
      return e;
    }
  }
);

const deleteAICMinibusiness = createAsyncThunk(
  "AICMinibusiness/delete",
  async (id) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.AICMiniBusiness.delete.replace("{Id}", id);
      await axios.delete(url, requestOptions);
      return id;
    } catch (e) {
      return e;
    }
  }
);

export {
  addAICMinibusiness,
  deleteAICMinibusiness,
  getAICMinibusinesses,
  editAICMinibusiness,
  getAICMinibusinessesById,
};
