export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
export const cloneObj = (obj) => {
  const newObj = JSON.parse(JSON.stringify(obj));
  return newObj;
};
export const countTrueProps = (obj) => {
  let count = 0;
  for (const key in obj) {
    if (key !== "canBeReplicated") {
      if (obj[key]) {
        count++;
      }
    }
  }
  return count;
};
export const upperCaseFirstLetter = (str) => {
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2.trim();
};

export const makeYearsForSelect = (from, to) => {
  const years = [];
  for (let i = from; i <= to; i++) {
    years.push({
      id: i,
      value: i,
      text: i,
    });
  }
  return years;
};
