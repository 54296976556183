import React from 'react'

function CustomCheckbox({ value, id, label, register, checked }) {
  return (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        value={value}
        type="checkbox"
        defaultChecked={checked}
        id={id}
        {...register}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export default CustomCheckbox