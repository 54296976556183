import React from "react";
import { uri } from '../../constants/API_URLS'

export default function FilesImage({ path, alt }) {
  return <img
    style={{ paddingRight: "5px" }}
    src={`${uri}/file/${path}`}
    alt={alt}
    width="250px"
    height="200px"
  />
}
