import { createSlice, current } from "@reduxjs/toolkit";
import {
  getBusinessUnits,
  getBusinessUnitNames,
  addBusinessUnit,
  deleteBusinessUnit,
  editBusinessUnit,
} from "./factoryMinibusinessUnitActions";

const initialState = {
  businessUnitNames: [],
  businessUnits: [],
};

export const businessUnitSlice = createSlice({
  name: "businessUnit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBusinessUnitNames.fulfilled, (state, action) => {
      state.businessUnitNames = action.payload;
    });
    builder.addCase(getBusinessUnits.fulfilled, (state, action) => {
      state.businessUnits = action.payload;
    });
    builder.addCase(addBusinessUnit.fulfilled, (state, action) => {});
    builder.addCase(deleteBusinessUnit.fulfilled, (state, action) => {
      const newState = current(state).businessUnits.filter(
        (businessUnit) => businessUnit._id !== action.payload
      );
      state.businessUnits = newState;
    });
    builder.addCase(editBusinessUnit.fulfilled, (state, action) => {
      const newState = current(state).businessUnits.map((businessUnit) => {
        if (businessUnit._id === action.payload._id) {
          return action.payload;
        } else {
          return businessUnit;
        }
      });
      state.businessUnits = newState;
    });
  },
});

export default businessUnitSlice.reducer;
