import {configureStore} from "@reduxjs/toolkit";
import {useDispatch, useSelector as rawUseSelector} from "react-redux";
import logger from "redux-logger";
import rootReducer from "store/rootReducer";
import {authSlice} from "store/slices/auth/authSlice";
import {userSlice} from "store/slices/users/userSlice";
import {questionsSlice} from "store/slices/questions/questionsSlice";
import {analyticsSlice} from "store/slices/analytics/analyticsSlice";
import {reduxBatch} from "@manaflair/redux-batch";

const preloadedState = {
  auth:authSlice.getInitialState(),
  user: userSlice.getInitialState(),
  questions: questionsSlice.getInitialState(),
  analytics: analyticsSlice.getInitialState(),
}
export const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
  enhancers: [reduxBatch],
});

export const useAppDispatch = () => useDispatch();
export const useSelector = rawUseSelector;

