import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getCompanyNames = createAsyncThunk(
  "company/getCompanyNames",
  async () => {
    try {
      const response = await axios.get(API_URLS.Company.getNames);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getCompanies = createAsyncThunk(
  "company/all",
  async () => {
    try {
      const response = await axios.get(API_URLS.Company.get);
      response.data.shift();
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
const addCompany = createAsyncThunk(
  "company/post",
  async (data) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios.post(API_URLS.Company.post, data, requestOptions);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
const editCompany = createAsyncThunk("company/editCompany", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.Company.put.replace("{Id}", data._id);
    await axios.put(url, data, requestOptions);
    return data;
  } catch (e) {
    return e;
  }
});

const deleteCompany = createAsyncThunk("company/delete", async (id) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.Company.delete.replace("{Id}", id);
    await axios.delete(url, requestOptions);
    return id;
  } catch (e) {
    return e;
  }
});

export { getCompanies, getCompanyNames, addCompany, deleteCompany, editCompany };
