import { createSlice } from "@reduxjs/toolkit";
import { API_URLS } from "constants/API_URLS";
import { asyncGetCount } from "../utils/utilsGetCount";

const initialState = {
  ideas: { count: 0 },
  clients: { count: 0 },
  projects: { count: 0 },
  users: { count: 0 },
  assessments: { count: 0 },
};

export const getIdeasCount = asyncGetCount("idea", API_URLS.Analytics.ideas);
export const getClientsCount = asyncGetCount(
  "clients",
  API_URLS.Analytics.clients
);
export const getProjectsCount = asyncGetCount(
  "projects",
  API_URLS.Analytics.projects
);
export const getUsersCount = asyncGetCount("users", API_URLS.Analytics.users);
export const getAssessmentsCount = asyncGetCount(
  "assessments",
  API_URLS.Analytics.assessments
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIdeasCount.fulfilled, (state, action) => {
      state.ideas = action.payload;
    });
    builder.addCase(getClientsCount.fulfilled, (state, action) => {
      state.clients = action.payload;
    });
    builder.addCase(getProjectsCount.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(getUsersCount.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getAssessmentsCount.fulfilled, (state, action) => {
      state.assessments = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { getIdeasCount } = analyticsSlice.actions;

export default analyticsSlice.reducer;
