import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { Col } from 'reactstrap';
import { animatedComponents, getFilterFromLocalStorage } from '../../utils/helpers';

function CustomFilter({ updateFilterFunction, filterOptions, filterId, filterLabel, defaultValueKey, updateFilterKey }) {
    const { t } = useTranslation();
    return (
        <Col className="mb-3" md="3" xs="12">
            <label
                className="form-control-label"
                htmlFor={filterId}
            >
                {t(filterLabel)}
            </label>
            <Select
                defaultValue={getFilterFromLocalStorage(defaultValueKey)}
                id={filterId}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                onChange={(value) => {
                    updateFilterFunction({ [updateFilterKey]: value });
                }}
                options={filterOptions}
            />
        </Col>
    )
}

export default CustomFilter
