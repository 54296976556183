import { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import confirm from "reactstrap-confirm";
import { API_URLS } from '../../constants/API_URLS';
import { deleteVideo } from '../../store/slices/videos/videosActions';
import { pagination } from '../../utils/helpers';
import style from './VideosTable.module.scss';

const { SearchBar } = Search;


function VideosTable({ videos, isAllowed }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [row, setRow] = useState({});

    const onSpanClick = (data) => {
        setModal(true);
        setRow(data);
    }
    const handleDelete = async (id, videoId) => {
        let result = await confirm({
            title: <>Warning</>,
            message: t("Are you sure you want to delete this?"),
            confirmText: "Confirm",
            confirmColor: "primary",
            cancelColor: "link text-danger",
        });
        if (result) dispatch(deleteVideo({ id, videoId }));
    }
    return (
        <ToolkitProvider
            data={videos}
            keyField="#"
            columns={[
                {
                    dataField: "_id",
                    text: 'ID',
                    align: 'left',
                    hidden: 'true'
                },
                {
                    dataField: 'videoName',
                    text: 'Video name',
                },
                {
                    dataField: '#',
                    text: 'View',
                    formatter: (_, row) => (
                        <span className={style.playSpan} onClick={() => onSpanClick(row)}>
                            Play video
                        </span>
                    )
                },
                isAllowed ?? {
                    dataField: "_id",
                    text: t("idea.delete"),
                    formatter: (_, row) => (
                        <span
                            className="table-action table-action-delete"
                            style={{ cursor: 'pointer' }}
                            id={row.videoId}
                            onClick={() => handleDelete(row._id, row.videoId)}
                        >
                            <i className="fas fa-trash" />
                        </span>
                    ),
                },
            ]}
            search
        >
            {(props) => (
                <div className="py-4 table-responsive">
                    <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                    >
                        <div className="d-flex flex-row align-items-center pb-2" style={{ gap: '2rem' }}>
                            <SearchBar
                                className="form-control"
                                placeholder=""
                                {...props.searchProps}
                            />
                        </div>
                    </div>
                    <BootstrapTable
                        {...props.baseProps}
                        hover={true}
                        tabIndexCell={true}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        keyField="index"
                        data={videos}
                    />
                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle}>{row.videoName}</ModalHeader>
                        <ModalBody>
                            <div className={style.videoContainer}>
                                <video className={style.video} controls autoPlay>
                                    <source
                                        src={API_URLS.File.getOne + row.videoId}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            )}
        </ToolkitProvider>
    )
}

export default VideosTable