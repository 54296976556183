import { createSlice, current } from "@reduxjs/toolkit";
import {
  getareaSections,
  addareaSection,
  deleteAreaSection,
  editAreaSection,
  getareaSectionsById,
} from "./areaSectionActions";
const initialState = {
  areaSections: [],
};

export const areaSectionSlice = createSlice({
  name: "areaSection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getareaSections.fulfilled, (state, action) => {
      state.areaSections = action.payload;
    });
    builder.addCase(getareaSectionsById.fulfilled, (state, action) => {
      state.areaSections = action.payload;
    });
    builder.addCase(addareaSection.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.areaSections = newState;
    });
    builder.addCase(deleteAreaSection.fulfilled, (state, action) => {
      const newState = current(state).areaSections.filter(
        (areaSection) => areaSection._id !== action.payload
      );
      state.areaSections = newState;
    });
    builder.addCase(editAreaSection.fulfilled, (state, action) => {
      const newState = current(state).areaSections.map((areaSection) => {
        if (areaSection._id === action.payload._id) {
          return action.payload;
        } else {
          return areaSection;
        }
      });
      state.areaSections = newState;
    });
  },
});

export default areaSectionSlice.reducer;
