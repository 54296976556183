import { createSlice, current } from "@reduxjs/toolkit";
import {
  addScore,
  deleteIdea,
  getFilteredIdeas,
  getIdeaScores,
  getIdeas,
  getIdeasByemployeeCode,
  getImportedIdeas,
  ideaSubmit,
  updateIdea,
} from "./ideaActions";
const initialState = {
  ideas: [],
  ideaScores: [],
  importedIdeas: [],
};

export const ideaSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIdeas.fulfilled, (state, action) => {
      state.ideas = action.payload;
    });
    builder.addCase(getIdeasByemployeeCode.fulfilled, (state, action) => {
      state.ideasByCode = action.payload;
    });
    builder.addCase(getFilteredIdeas.fulfilled, (state, action) => {
      state.ideas = action.payload;
    });
    builder.addCase(getImportedIdeas.fulfilled, (state, action) => {
      state.importedIdeas = action.payload;
    });
    builder.addCase(deleteIdea.fulfilled, (state, action) => {
      const newState = current(state).ideas.filter(
        (idea) => idea._id !== action.payload
      );
      state.ideas = newState;
    });
    builder.addCase(updateIdea.fulfilled, (state, action) => {});
    builder.addCase(addScore.fulfilled, (state, action) => {});
    builder.addCase(getIdeaScores.fulfilled, (state, action) => {
      state.ideaScores = action.payload;
    });
    builder.addCase(ideaSubmit.fulfilled, (state, action) => {});
  },
});

// Action creators are generated for each case reducer function
// export const {} = companySlice.actions;

export default ideaSlice.reducer;
