import { createSlice, current } from "@reduxjs/toolkit";
import {
  addVideo,
  deleteVideo,
  getVideos,
  updateUploadPercentage,
} from "./videosActions";
const initialState = {
  videos: [],
  videoPercent: 0,
};

const VideoSlice = createSlice({
  name: "Video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
      })
      .addCase(addVideo.fulfilled, (state, action) => {
        const newState = current(state).push(action.payload);
        state.videos = newState;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        const newState = current(state).videos.filter(
          (AICMinibusiness) => AICMinibusiness._id !== action.payload
        );
        state.videos = newState;
      })
      .addCase(updateUploadPercentage, (state, action) => {
        state.videoPercent = action.payload;
      });
  },
});

export default VideoSlice.reducer;
