import { createSlice, current } from "@reduxjs/toolkit";
import { addFirstCut, deleteFirstCut, editFirstCut, getFirstCuts, getFirstCutsById } from "./firstCutActions";
const initialState = {
    firstCuts: []
};

export const firstCutSlice = createSlice({
    name: "firstCut",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFirstCuts.fulfilled, (state, action) => {
            state.firstCuts = action.payload;
        });
        builder.addCase(getFirstCutsById.fulfilled, (state, action) => {
            state.firstCuts = action.payload;
        });
        builder.addCase(addFirstCut.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.firstCuts = newState;
        });
        builder.addCase(deleteFirstCut.fulfilled, (state, action) => {
            const newState = current(state).firstCuts.filter(
                (firstCut) => firstCut._id !== action.payload
            );
            state.firstCuts = newState;
        });
        builder.addCase(editFirstCut.fulfilled, (state, action) => {
            const newState = current(state).firstCuts.map(
                (firstCut) => {
                    if (firstCut._id === action.payload._id) { return (action.payload) } else { return firstCut }
                }
            );
            state.firstCuts = newState;
        });
    },
});

export default firstCutSlice.reducer;
