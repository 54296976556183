import { createSlice, current } from "@reduxjs/toolkit";
import {
  addEmployeeCodeSheet,
  deleteEmployeeCodeSheet,
  editEmployeeCodeSheet,
  getEmployeeCodeSheets,
} from "./EmployeeCodeSheetActions";
const initialState = {
  employeeCodeSheets: [],
};

const EmployeeCodeSheetSlice = createSlice({
  name: "EmployeeCodeSheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeeCodeSheets.fulfilled, (state, action) => {
      state.employeeCodeSheets = action.payload;
    });
    builder.addCase(addEmployeeCodeSheet.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.employeeCodeSheets = newState;
    });
    builder.addCase(deleteEmployeeCodeSheet.fulfilled, (state, action) => {
      const newState = current(state).employeeCodeSheets.filter(
        (AICMinibusiness) => AICMinibusiness._id !== action.payload
      );
      state.employeeCodeSheets = newState;
    });
    builder.addCase(editEmployeeCodeSheet.fulfilled, (state, action) => {
      const newState = current(state).employeeCodeSheets.map(
        (AICMinibusiness) => {
          if (AICMinibusiness._id === action.payload._id) {
            return action.payload;
          } else {
            return AICMinibusiness;
          }
        }
      );
      state.employeeCodeSheets = newState;
    });
  },
});

export default EmployeeCodeSheetSlice.reducer;
