import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getVideos = createAsyncThunk("Video/all", async () => {
  try {
    const response = await axios.get(API_URLS.Video.get);
    return response.data;
  } catch (error) {
    return error;
  }
});

const updateUploadPercentage = createAction("video/updateUploadPercentage");

const addVideo = createAsyncThunk(
  "Video/post",
  async ({ file, alertFun, t }, { dispatch }) => {
    try {
      let fileData = new FormData();
      fileData.append("file", file);
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          dispatch(updateUploadPercentage(Math.floor((loaded * 100) / total)));
        },
      };

      const response = await axios.post(
        API_URLS.Video.post,
        fileData,
        requestOptions
      );
      alertFun("success", t("Your file has been uploaded"));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return response.data;
    } catch (error) {
      alertFun("danger", t("An error occured please try again"));
      return error;
    }
  }
);

const deleteVideo = createAsyncThunk(
  "Video/delete",
  async ({ id, videoId }) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = `${API_URLS.Video.delete}${id}/${videoId}`;
      await axios.delete(url, requestOptions);
      return id;
    } catch (e) {
      return e;
    }
  }
);

export { addVideo, deleteVideo, getVideos, updateUploadPercentage };
