import { createSlice, current } from "@reduxjs/toolkit";
import { addSecondCut, deleteSecondCut, editSecondCut, getSecondCuts, getSecondCutsById } from "./secondCutActions";
const initialState = {
    secondCuts: []
};

export const secondCutSlice = createSlice({
    name: "secondCut",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSecondCuts.fulfilled, (state, action) => {
            state.secondCuts = action.payload;
        });
        builder.addCase(getSecondCutsById.fulfilled, (state, action) => {
            state.secondCuts = action.payload;
        });
        builder.addCase(addSecondCut.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.secondCuts = newState;
        });
        builder.addCase(deleteSecondCut.fulfilled, (state, action) => {
            const newState = current(state).secondCuts.filter(
                (secondCut) => secondCut._id !== action.payload
            );
            state.secondCuts = newState;
        });
        builder.addCase(editSecondCut.fulfilled, (state, action) => {
            const newState = current(state).secondCuts.map(
                (secondCut) => {
                    if (secondCut._id === action.payload._id) { return (action.payload) } else { return secondCut }
                }
            );
            state.secondCuts = newState;
        });
    },
});

export default secondCutSlice.reducer;
