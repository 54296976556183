export const UserTypeSelect = [
  {
    id: "ADMIN",
    value: "ADMIN",
    text: "admin",
  },
  {
    id: "USER",
    value: "USER",
    text: "user",
  },
  {
    id: "CONSULTANT",
    value: "CONSULTANT",
    text: "consultant",
  },
  {
    id: "OPEX_TEAM_MEMBER",
    value: "OPEX_TEAM_MEMBER",
    text: "opexTeamMember",
  },
  {
    id: "CLIENT",
    value: "CLIENT",
    text: "client",
  },
  {
    id: "CORPORATE",
    value: "CORPORATE",
    text: "corporate",
  },
  {
    id: "MBUL",
    value: "MBUL",
    text: "mbul",
  },
  {
    id: "PROMOTER",
    value: "PROMOTER",
    text: "promoter",
  },
  {
    id: "KEY_OWNER",
    value: "KEY_OWNER",
    text: "keyOwner",
  },
];
