import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getIdeas = createAsyncThunk("idea/getIdeas", async () => {
  try {
    const response = await axios.get(API_URLS.Idea.CRUD.get);
    return response.data;
  } catch (error) {
    return error;
  }
});

const getIdeasByemployeeCode = createAsyncThunk(
  "idea/getIdeasByemployeeCode",
  async ({ employeeCode, type }) => {
    try {
      if (type === "Employee ID") {
        const response = await axios.get(
          API_URLS.Idea.CRUD.getIdeasByCode + employeeCode
        );
        return response.data;
      } else {
        const response = await axios.get(
          API_URLS.Idea.CRUD.getOne + employeeCode
        );
        return [response.data];
      }
    } catch (error) {
      return error;
    }
  }
);

const getFilteredIdeas = createAsyncThunk(
  "idea/getFilteredIdeas",
  async (opts) => {
    try {
      const response = await axios.post(
        API_URLS.Idea.CRUD.getFilteredIdeas,
        opts
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getImportedIdeas = createAsyncThunk("idea/getImportedIdeas", async () => {
  try {
    const response = await axios.get(API_URLS.Idea.CRUD.getAllImported);
    return response.data;
  } catch (error) {
    return error;
  }
});
const deleteIdea = createAsyncThunk("users/deleteIdea", async (id) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    await axios.delete(`${API_URLS.Idea.CRUD.post}/${id}`, requestOptions);
    return id;
  } catch (e) {
    return e;
  }
});
const updateIdea = createAsyncThunk(
  "idea/updateIdea",
  async ({
    idea,
    file,
    operatorImg,
    dispatch,
    path,
    history,
    setIsLoading,
  }) => {
    try {
      const fileIds = [];
      //appendig files to upload them
      let imageData = new FormData();
      let operatorImage = new FormData();
      let operatorImageResponse = "";
      if (idea.key === "none" && idea.type !== "status") {
        const oldIdea = await axios.get(API_URLS.Idea.CRUD.getOne + idea.id);
        const { filesRelated: oldFilesRelated, operatorImg: oldOperatorImg } =
          oldIdea.data;
        if (operatorImg?.name) {
          oldOperatorImg &&
            (await axios.delete(API_URLS.File.delete + oldOperatorImg));
          operatorImage.append("file", operatorImg);
          operatorImageResponse = await axios.post(
            API_URLS.File.upload,
            operatorImage,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        imageData.append("file", file);
        //make the post req
        const filesResponse = await axios.post(
          API_URLS.File.upload,
          imageData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // taking ids of files uploaded
        filesResponse.data.forEach((element) => {
          fileIds.push({
            _id: element._id,
            filename: element.filename,
            contentType: element.contentType,
          });
        });
        idea.data.filesRelated = fileIds;
        idea.data.operatorImg = operatorImageResponse
          ? {
              _id: operatorImageResponse.data[0]?._id,
              filename: operatorImageResponse.data[0]?.filename,
              contentType: operatorImageResponse.data[0]?.contentType,
            }
          : operatorImageResponse;
      }
      if (
        idea.type === "status" &&
        idea.key === "implemented" &&
        idea.data.implemented.accepted
      ) {
        //add pics to implemented status
        let fileBefore = new FormData();
        let fileAfter = new FormData();
        fileBefore.append("file", idea.data.implemented.before);
        fileAfter.append("file", idea.data.implemented.after);
        const [imgBefore, imgAfter] = await Promise.all([
          axios.post(API_URLS.File.upload, fileBefore),
          axios.post(API_URLS.File.upload, fileAfter),
        ]);
        idea.data.implemented.before = {
          _id: imgBefore.data[0]?._id,
          filename: imgBefore.data[0]?.filename,
          contentType: imgBefore.data[0]?.contentType,
        };
        idea.data.implemented.after = {
          _id: imgAfter.data[0]?._id,
          filename: imgAfter.data[0]?.filename,
          contentType: imgAfter.data[0]?.contentType,
        };
      }
      (operatorImg?.name === "" || operatorImg?.name === undefined) &&
        delete idea?.data?.operatorImg;
      file === "" && delete idea?.data?.filesRelated;

      const token = localStorage.getItem("opexDashbaordToken");
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      let url = `${API_URLS.Idea.CRUD.updateStatus}${idea.id}/${idea.key}`;
      if (idea.key === "none") {
        url = API_URLS.Idea.CRUD.put.replace("{Id}", idea.id);
      }
      const response = await axios.put(url, idea.data, requestOptions);
      setTimeout(() => {
        dispatch(setIsLoading(false));
        history.push(path);
        history.go(0);
      }, 1500);
      return response.data;
    } catch (e) {
      dispatch(setIsLoading(false));
      return e;
    }
  }
);

const getIdeaScores = createAsyncThunk("idea/getIdeaScores", async () => {
  try {
    const response = await axios.get(API_URLS.Idea.Score.getAll);
    return response.data;
  } catch (error) {
    return error;
  }
});

const addScore = createAsyncThunk("idea/addScore", async (score) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const { idea } = score;
    const ideaRes = await axios.post(
      API_URLS.Idea.Score.post,
      score,
      requestOptions
    );
    await axios.put(
      API_URLS.Idea.CRUD.put.replace("{Id}", idea),
      {
        hasScore: {
          value: true,
          scoreId: ideaRes.data._id,
        },
      },
      requestOptions
    );
    return score;
  } catch (e) {
    return e;
  }
});

const importFile = createAsyncThunk("idea/importFile", async (ideas) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await axios.post(
      API_URLS.Idea.CRUD.post,
      ideas,
      requestOptions
    );
    return response.data;
  } catch (e) {
    return e;
  }
});

const ideaSubmit = createAsyncThunk(
  "idea/submit",
  async ({ data, file, operatorImg, alertFun }) => {
    try {
      const fileIds = [];
      //appendig files to upload them
      let imageData = new FormData();
      let operatorImage = new FormData();
      let operatorImageResponse = "";
      if (operatorImg) {
        operatorImage.append("file", operatorImg);
        operatorImageResponse = await axios.post(
          API_URLS.File.upload,
          operatorImage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      imageData.append("file", file);
      //make the post req
      const filesResponse = await axios.post(API_URLS.File.upload, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // taking ids of files uploaded
      filesResponse.data.forEach((element) => {
        fileIds.push({
          _id: element._id,
          filename: element.filename,
          contentType: element.contentType,
        });
      });
      // add them to the idea to make the ful object to be sent
      const fullIdea = {
        ...data,
        filesRelated: fileIds,
        operatorImg: operatorImageResponse
          ? {
              _id: operatorImageResponse.data[0]?._id,
              filename: operatorImageResponse.data[0]?.filename,
              contentType: operatorImageResponse.data[0]?.contentType,
            }
          : operatorImageResponse,
      };
      fullIdea.status.implemented.before = fileIds[0];
      const submittedIdea = await axios.post(API_URLS.Idea.CRUD.post, fullIdea);
      alertFun(`Your idea code is: ${submittedIdea.data._id}`);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return submittedIdea.data;
    } catch (e) {
      console.log(e);
    }
  }
);
const importBackupFile = createAsyncThunk(
  "idea/importFile",
  async ({ file, alertFun }) => {
    try {
      if (file?.name) {
        const formData = new FormData();
        formData.append("file", file);
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        const response = await axios.post(
          API_URLS.Idea.CRUD.importBackupfile,
          formData,
          requestOptions
        );
        const { data } = response;
        alertFun(
          "success",
          `message : ${data?.message}\nIgnored : ${
            data?.ignoredCount
          }\nImported : ${data?.importedCount}\nNew : ${data?.newData.join()}`,
          { whiteSpace: "pre-line" }
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return response;
      }
    } catch (e) {
      console.log({ e });
      return e;
    }
  }
);
export {
  getIdeas,
  deleteIdea,
  updateIdea,
  addScore,
  getIdeaScores,
  importFile,
  ideaSubmit,
  getImportedIdeas,
  getFilteredIdeas,
  getIdeasByemployeeCode,
  importBackupFile,
};
