import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Button, Card, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { notify, validateAndAddError } from "../../../../utils/helpers";
import ErrorMessage from "../../../../components/ErrorMessage/ErrorMessage";
import { rejectedReasons, statusApproval } from "../../../../utils/Data";

function Rewarded({ data, view, setChangedData, id }) {
    const notificationAlertRef = useRef(null);
    const { t, i18n } = useTranslation();
    const [dataChanged, setDataChanged] = useState(false);
    const [savings, setSavings] = useState(0);
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState(new Date().toISOString().split('T')[0]);
    const [validationErrors, setValidationErrors] = useState({});
    const [approved, setApproved] = useState(data[1]?.accepted || true);
    const [holdReason, setHoldReason] = useState(!view && data[1]?.accepted === 'hold' ? data[1]?.reasonOfHold : '');
    const [rejectedReason, setRejectedReason] = useState('High cost');
    const [other, setOther] = useState('');


    const saveChangesHandler = () => {
        let errors = {};
        // Check for validation errors
        if (!((/hold/i).test(approved) || (/false/i).test(approved))) {
            validateAndAddError(errors, 'eventName', eventName, 'text', i18n.language);

            setValidationErrors(errors);

            if (Object.keys(errors).length > 0) {
                return;
            }
        }
        notify(
            "success",
            'Your changes have been saved.',
            notificationAlertRef,
            'Saved Successfully'
        );
        const rewardedChanges = {
            eventName,
            eventDate,
            savings,
            value: true,
            date: new Date(),
            accepted: approved,
            reasonOfHold: holdReason,
            reasonOfDenial: rejectedReason === "Other" ? other : approved && rejectedReason,
        }
        if ((/hold/i).test(approved)) {
            delete rewardedChanges.value;
            delete rewardedChanges.date;
        }
        setChangedData({
            data: {
                rewarded:
                    rewardedChanges,
            },
            key: 'rewarded',
            id: id
        });
    }
    return (
        <Card className="w-100 p-3" >
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <CardHeader>
                <p className="h3 text-capitalize">
                    {t('editIdea.Rewarded')}
                </p>
            </CardHeader>
            <Row className="w-100">
                {/* approved*/}
                <Col className="m-5" md="4">
                    <FormGroup>
                        <p className="h3">Evaluation decision</p>
                        <Input
                            type="select"
                            disabled={view}
                            value={approved}
                            onChange={(e) => {
                                setApproved(e.target.value);
                                setDataChanged(true)
                            }}
                        >
                            {statusApproval.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
                        </Input>
                    </FormGroup>
                </Col>

                {/* hold reason*/}
                {(/hold/i).test(approved) && <Col className="m-5" md="4">
                    <p className="h3">{t('evaluated.hold reason')}</p>
                    <Input
                        placeholder={t('evaluated.hold reason')}
                        value={holdReason}
                        onChange={(e) => setHoldReason(e.target.value)}
                        type="text"
                    />
                </Col>}
                {/* rejected reason*/}
                {(/false/i).test(approved) && <Col className="m-5" md="4">
                    <FormGroup>
                        <p className="h3">{t('evaluated.rejected reason')}</p>
                        <Input
                            type="select"
                            disabled={view}
                            value={rejectedReason}
                            onChange={(e) => setRejectedReason(e.target.value)}
                        >
                            {rejectedReasons.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
                        </Input>
                    </FormGroup>
                </Col>}

                {/* other */}
                {((/false/i).test(approved) && rejectedReason === 'Other') && <Col className="m-5" md="4">
                    <p className="h3">{t('evaluated.Other')}</p>
                    <Input
                        placeholder="Other"
                        disabled={view}
                        value={other}
                        onChange={(e) => {
                            setOther(e.target.value)
                        }}
                        type="text"
                    />
                </Col>}

                {/* Rewarded */}
                {(data[1].value || (/true/i).test(approved)) && (
                    <>
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.Reward')}</p>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Reward"
                                value={view ? data[1]?.savings : savings}
                                disabled={view}
                                min={0}
                                onChange={(e) => {
                                    setSavings(+e.target.value)
                                }}
                            />
                        </Col>
                        {/* event name */}
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.eventName')}</p>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('editIdea.eventName')}
                                value={view ? data[1]?.eventName : eventName}
                                disabled={view}
                                min={0}
                                onChange={(e) => {
                                    setEventName(e.target.value)
                                }}
                            />
                            <ErrorMessage errors={validationErrors} property='eventName' />
                        </Col>
                        {/* event date */}
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.eventDate')}</p>
                            <Input
                                disabled={view}
                                value={view ? data[1].eventDate : eventDate}
                                type="date"
                                onChange={(e) => {
                                    setEventDate(e.target.value)
                                }}
                            />
                        </Col>
                    </>)}
            </Row>
            <div className="text-center">
                {!view && <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || view} >
                    {t('evaluated.saveChanges')}
                </Button>}
            </div>
        </Card>
    )
}

export default Rewarded