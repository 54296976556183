import React from "react";
import style from "./Modal.module.scss";

const Modal = ({ visible, children }) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={style.customModal}>
      <div className={style.customModalOverlay} />
      <div className={style.customModalContent}>{children}</div>
    </div>
  );
};

export default Modal;
