/*eslint-disable*/
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container, FormGroup,
  Input, Row
} from "reactstrap";
import confirm from "reactstrap-confirm";
import * as xlsx from "xlsx";
import { API_URLS, uri } from "../../../constants/API_URLS";
import { deleteIdea, getImportedIdeas } from "../../../store/slices/idea/ideaActions";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const IdeaImport = () => {
  const [importedFiles, setImportedFiles] = useState([]);
  const ideas = useSelector((state) => state.idea.importedIdeas) || [];
  const user = useSelector((state) => state.user.user);
  const [selectIdeaStatus, setSelectIdeaStatus] = useState("none");
  const filteredIdeas = ideas?.filter(
    (idea) => {
      return idea?.status?.[selectIdeaStatus]?.value === true
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getImportedIdeas());
  }, []);
  const deleteOne = async (id) => {
    let result = await confirm({
      title: <>Warning</>,
      message: t("Are you sure you want to delete this?"),
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    if (result) dispatch(deleteIdea(id));
  };
  const readUploadFile = (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        json.forEach(el => {
          el.imported = true;
          el.status = {
            evaluated: {
              value: false,
              canBeReplicated: false,
              questions: {
                isItCheap: false,
                isItEasyToImplement: false,
                canWeImplementItOurselves: false,
                canItBeImplementedWithin3Weeks: false,
                isItOneOfOurTop3FavSuggestions: false,
                willItSaveAsignificantAmout: false,
                isItUrgent: false,
                totalTicks: 0,
                priority: "Low",
                dueDate: "",
                responsiblePerson: "",
              },
            },
            approved: {
              value: false,
              questions: {
                "Will not negatively affect quality/operator/saftey/machine?": false,
                "Will have impact?": false,
                "Did stakeholders approve?": false,
              },
              canBeReplicated: false,
            },
            implemented: {
              value: false,
              savings: 0,
              before: "",
              after: "",
            },
            rewarded: { value: false, savings: 0 },
            // replicated: { value: false },
          }
        });
        setImportedFiles(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }
  const onImportFiles = async () => {
    try {
      let result = await confirm({
        title: <>Warning</>,
        message: "Are you sure you want to import this file?",
        confirmText: "Confirm",
        confirmColor: "primary",
        cancelColor: "link text-danger",
      });
      if (importedFiles.length && result) {
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        await axios.post(
          API_URLS.Idea.CRUD.post,
          importedFiles,
          requestOptions
        );
        history.go(0);
        // dispatch(importFile(importedFiles));
      };
    } catch (e) {
      console.log(e);
    }
  }
  // const onSelectIdeaStatusChange = () => {};
  return (
    <>
      <SimpleHeader name="Ideas" parentName="Idea management" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Imported Ideas</h3>
              </CardHeader>
              <ToolkitProvider
                data={ideas}
                keyField="#"
                columns={[
                  {
                    dataField: "_id",
                    text: "ID",
                    // width: 1,
                    align: "left",
                  },
                  {
                    dataField: "company",
                    text: "Company",
                    sort: true,
                    // width: 1,
                    align: "left",
                  },
                  {
                    dataField: "factory",
                    text: "Factory",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "miniBusiness",
                    text: "Mini Business",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "department",
                    text: "Department",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "stage",
                    text: "Stage",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "firstCut",
                    text: "First Cut",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "secondCut",
                    text: "Second Cut",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "effectOnPQCDSM",
                    text: "Effect on PQCDSM",
                    formatter: (cell) => {
                      return cell + " ";
                    },
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "ideaText",
                    text: "Idea",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "ideaCode",
                    text: "Idea Code",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "employeeName",
                    text: "Employee",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "employeeCode",
                    text: "Employee Code",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "kaizenType",
                    text: "Kaizen Type",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "estimatedAnualSavings",
                    text: "Estimated Savings | Employee",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "quarter",
                    text: "Quarter",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "year",
                    text: "Year",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "replicationStatus",
                    text: "Replication Case",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "status.evaluated.questions.totalTicks",
                    text: "Total Ticks",
                  },
                  {
                    dataField: "status.evaluated.questions.priority",
                    text: "Priority",
                  },
                  // {
                  //   dataField: "estimatedSavings",
                  //   text: "Estimated Savings | Employee",
                  // },
                  {
                    dataField: "status.approved.estimatedSavings.amount",
                    text: "Estimated Savings Amount | Manger",
                  },
                  {
                    dataField: "status.approved.estimatedSavings.type",
                    text: "Estimated Savings Type | Manger",
                  },
                  {
                    dataField: "status.approved.approvedBy",
                    text: "Approved By",
                  },
                  {
                    dataField: "status.implemented.cost",
                    text: "Cost",
                  },
                  {
                    dataField: "status.implemented.savings",
                    text: "Savings",
                  },
                  {
                    dataField: "status.implemented.roi",
                    text: "ROI",
                  },
                  {
                    dataField: "status.rewarded.savings",
                    text: "Reward",
                  },
                  {
                    dataField: "status.replicated.from",
                    text: "Replicated From",
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    formatter: (cell, row) => {
                      if (cell)
                        return (
                          <>
                            {Object.entries(cell).map((el) => {
                              return (
                                <Button
                                  key={el[0]}
                                  size="sm"
                                  type="button"
                                  color={el[1].value ? "success" : "danger"}
                                >
                                  <span className="text-capitalize">{el[0]}</span>
                                </Button>
                              );
                            })}
                            {/* can be replicated */}
                            {
                              <Button
                                size="sm"
                                type="button"
                                color={
                                  cell.approved?.canBeReplicated ||
                                    cell.evaluated?.canBeReplicated
                                    ? "success"
                                    : "secondary"
                                }
                              >
                                <span className="text-capitalize">can be replicated</span>
                              </Button>
                            }
                          </>
                        )
                    },
                  },
                  {
                    dataField: "filesRelated",
                    text: "Files related",
                    formatter: (cell) => (
                      <>
                        {cell?.map((el) => {
                          if (el.contentType === "application/pdf") {
                            return (
                              <Col lg="12" key={el._id}>
                                <i className="ni ni-folder-17" />
                                <a
                                  className="ml-1"
                                  href={`${API_URLS.File.download + el?._id}`}
                                >
                                  {el.filename}
                                </a>
                              </Col>
                            );
                          } else {
                            return (
                              <img
                                key={el._id}
                                style={{ paddingRight: "5px" }}
                                src={uri + "/file/" + el?._id}
                                width="250px"
                                height="200px"
                              />
                            );
                          }
                        })}
                      </>
                    ),
                  },
                  //Edit
                  {
                    dataField: "_id",
                    text: "Edit",
                    formatter: (cell, row) => (
                      <>
                        {user?.userType === "ADMIN" ||
                          user?.userType === "CONSULTANT" ||
                          user?.userType === "MBUL" ||
                          user?.userType === "KEY_OWNER" ? (
                          <>
                            <Link
                              to={{
                                pathname: "/admin/editIdea/" + cell,
                                state: { status: row.status, PQCDSM: row.PQCDSM },
                              }}
                              key={cell}
                              className="text-primary"
                            >
                              Edit
                            </Link>
                          </>
                        ) : null}
                      </>
                    ),
                  },
                  //Delete
                  {
                    dataField: "_id",
                    text: "Delete",
                    formatter: (cell, row) => (
                      <>
                        {user?.userType === "ADMIN" ||
                          user?.userType === "CONSULTANT" ||
                          user?.userType === "MBUL" ||
                          user?.userType === "KEY_OWNER" ? (
                          <>
                            <span
                              className="table-action table-action-delete"
                              id={cell}
                              onClick={() => deleteOne(cell)}
                            >
                              <i className="fas fa-trash" />
                            </span>
                          </>
                        ) : null}
                      </>
                    ),
                  },
                  //add score
                  // {
                  //   dataField: "_id",
                  //   text: "Score",
                  //   formatter: (cell, row) => (
                  //     <>
                  //       {row.status.approved?.value &&
                  //       (user?.userType === "ADMIN" ||
                  //         user?.userType === "KEY_OWNER") ? (
                  //         <>
                  //           {!row.hasScore?.value ? (
                  //             <Link
                  //               to={{
                  //                 pathname: "/admin/addScore/" + cell,
                  //                 state: {
                  //                   employee: row.enterName,
                  //                 },
                  //               }}
                  //               key={cell}
                  //               className="text-primary"
                  //             >
                  //               Add Score
                  //             </Link>
                  //           ) : (
                  //             <p className="h5">Already has a score</p>
                  //           )}
                  //         </>
                  //       ) : null}
                  //     </>
                  //   ),
                  // },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Filter by idea status
                        </label>
                        <Input
                          id="exampleFormControlSelect3"
                          type="select"
                          onChange={(e) => setSelectIdeaStatus(e.target.value)}
                        >
                          <option value="none">All</option>
                          <option value="evaluated">Evaluated</option>
                          <option value="approved">Approved</option>
                          <option value="implemented">Implemented</option>
                          <option value="rewarded">Rewarded</option>
                          <option value='replicated'>Replicated</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      hover={true}
                      tabIndexCell={true}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      keyField="index"
                      data={
                        selectIdeaStatus === "none"
                          ? ideas?.map((row, index) => ({
                            ...row,
                            index,
                          }))
                          : filteredIdeas?.map((row, index) => ({
                            ...row,
                            index,
                          }))
                      }
                    />
                  </div>
                )}
              </ToolkitProvider>
              <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', }}>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                />
                <Button color="primary" type="button" style={{ width: '150px' }} onClick={onImportFiles} >Add</Button>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default IdeaImport;
