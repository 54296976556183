import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import 'react-select2-wrapper/css/select2.css';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Row
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { addDepartment } from "../../../store/slices/department/departmentActions";
import { getFactories } from "../../../store/slices/factory/factoryActions";
import { animatedComponents } from "../../../utils/helpers";

const AddDepartment = () => {
    const factories = useSelector((state) => state?.factory?.factories) || [];
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const englishName = register("englishName", {
        required: "Please enter department name in English",
        minLength: {
            value: 4,
            message: "Department name must be at least 4 characters",
        },
    });
    const arabicName = register("arabicName", {
        required: "Please enter department name in Arabic",
        minLength: {
            value: 4,
            message: "Department name must be at least 4 characters",
        },
    });
    const factory = register("factory", {
        required: "Please select at least one",
    });
    const handleFormSubmit = async ({ englishName, arabicName, factory }) => {
        const department = {
            name: {
                en: englishName,
                ar: arabicName
            },
            factory: factory.map(el => el.value)
        }
        let result = await confirm({
            title: <>Warning</>,
            message: "Are you sure you want to submit this department?",
            confirmText: "Confirm",
            confirmColor: "primary",
            cancelColor: "link text-danger",
        });
        if (result) {
            dispatch(addDepartment(department));
            history.push("/admin/department");
            history.go(0);
        };
    };
    useEffect(() => {
        if (!factories.legth)
            dispatch(getFactories());
    }, []);
    return (
        <>
            <SimpleHeader name="Add Department" parentName="Management" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Add</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form" onSubmit={handleSubmit(handleFormSubmit)}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <Controller
                                                    rules={{ required: "This is required" }}
                                                    name="factory"
                                                    control={control}
                                                    onChange={factory.onChange}
                                                    onBlur={factory.onBlur}
                                                    innerRef={factory.ref}
                                                    render={({ field }) => (
                                                        <>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="validationCustom03"
                                                            >
                                                                Factory
                                                            </label>
                                                            <Select
                                                                {...field}
                                                                closeMenuOnSelect={false}
                                                                components={animatedComponents}
                                                                isMulti
                                                                options={factories?.map((el) => {
                                                                    return {
                                                                        value: el._id,
                                                                        label: el.name.en,
                                                                    };
                                                                })}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <small className="text-danger">
                                                    {errors.factory && errors.factory.message}
                                                </small>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    English Name
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="English Name..."
                                                    type="text"
                                                    name="englishName"
                                                    onChange={englishName.onChange}
                                                    onBlur={englishName.onBlur}
                                                    innerRef={englishName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.englishName && errors.englishName.message}
                                                </small>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Arabic Name
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="Arabic Name..."
                                                    type="text"
                                                    name="arabicName"
                                                    onChange={arabicName.onChange}
                                                    onBlur={arabicName.onBlur}
                                                    innerRef={arabicName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.arabicName && errors.arabicName.message}
                                                </small>
                                            </Col>
                                        </div>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default AddDepartment;
