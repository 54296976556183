import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Row } from "reactstrap";
import VideosTable from "../../../components/VideosTable/VideosTable";
import { getVideos } from "../../../store/slices/videos/videosActions";
import style from './VideoViewer.module.scss';



export default function VideoViewer() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const videos = useSelector(
        (state) => state?.videos?.videos
    );

    useEffect(() => {
        dispatch(getVideos());
    }, []);

    return (
        <div className={style.pageContainer}>
            <Container className="pt-6">
                <Row>
                    <div className="col mt-7">
                        <Card className="bg-secondary border-0 mb-0">
                            <VideosTable videos={videos} isAllowed={false} />
                        </Card>
                    </div >
                </Row >
            </Container >
        </div>

    );
}
