import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

export const getUsers = createAsyncThunk("users/getAll", async () => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await axios.get(API_URLS.User.get, requestOptions);
    return response.data;
  } catch (e) {
    return e;
  }
});
export const getUsersCode = createAsyncThunk("users/getUsersCode", async () => {
  try {
    const response = await axios.get(API_URLS.User.getUsersCode);
    return response.data;
  } catch (e) {
    return e;
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (id) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    await axios.delete(`${API_URLS.User.post}/${id}`, requestOptions);
    return id;
  } catch (e) {
    return e;
  }
});

export const editUser = createAsyncThunk("users/editUser", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.User.put.replace("{Id}", data._id);
    const response = await axios.put(url, data, requestOptions);
    return response.data;
  } catch (e) {
    return e;
  }
});
