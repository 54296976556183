import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";
import { authToken } from "../../../constants/constants";

export const signupUser = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(API_URLS.auth.register, user);
      localStorage.setItem("user", JSON.stringify(response.data?.user));
      localStorage.setItem("opexDashbaordToken", response.data?.token);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/logIn",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post(
        API_URLS.auth.login,
        {
          email,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      localStorage.setItem("user", JSON.stringify(response.data?.user));
      localStorage.setItem("opexDashbaordToken", response.data?.token);
      return response.data.user;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const logOutUser = createAsyncThunk("auth/logOut", async (thunkAPI) => {
  try {
    localStorage.removeItem("opexDashbaordToken");
    localStorage.removeItem("user");
  } catch (err) {
    //@ts-ignore
    return thunkAPI.rejectWithValue(err);
  }
});

export const generateRefreshToken = createAsyncThunk(
  "auth/refreshToken",
  async ({ email, oldToken }, thunkAPI) => {
    try {
      const response = await axios.put(
        API_URLS.auth.refreshToken,
        {
          email,
          oldToken,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      localStorage.setItem("opexDashbaordToken", response.data?.token);
      return response.data.user;
    } catch (e) {
      thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const logOutAction = createAction(logOutUser.name);
