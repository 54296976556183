import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { notify } from "../../../../utils/helpers";
import ToggleCheckbox from "../../components/ToggleCheckbox/ToggleCheckbox";
import { Button, Card, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { estimatedSavingsType, rejectedReasons, statusApproval } from "../../../../utils/Data";

const Approved = ({ data, setChangedData, view, id }) => {
  const notificationAlertRef = useRef(null);
  const { t } = useTranslation();
  const [checkboxes, setCheckboxes] = useState({
    "Will not affect quality/operator/saftey/machine?": false,
    "Will have impact?": false,
    "Did stakeholders approve?": false,
    canBeReplicated: false,
  });
  const [estimatedSavings, setEstimatedSavings] = useState({
    amount: 0,
    type: "Hard Saving",
  });
  const [approved, setApproved] = useState(data[1]?.accepted || true);
  const [rejectedReason, setRejectedReason] = useState('High cost');
  const [holdReason, setHoldReason] = useState(!view && data[1]?.accepted === 'hold' ? data[1]?.reasonOfHold : '');
  const [other, setOther] = useState('');
  const [dataChanged, setDataChanged] = useState(false);

  const handleCheckboxChange = (e, key) => {
    !dataChanged && setDataChanged(true);
    setCheckboxes((prevState) => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };

  const saveChangesHandler = () => {
    notify(
      "success",
      'Your changes have been saved.',
      notificationAlertRef,
      'Saved Successfully'
    );
    const approvedChanges = {
      questions: {
        ...checkboxes,
      },
      accepted: approved,
      canBeReplicated: checkboxes.canBeReplicated,
      estimatedSavings: estimatedSavings,
      reasonOfDenial: rejectedReason === "Other" ? other : approved && rejectedReason,
      value: true,
      date: new Date(),
      reasonOfHold: holdReason
    }
    if ((/hold/i).test(approved)) {
      delete approvedChanges.value;
      delete approvedChanges.date;
    }

    setChangedData({
      data: {
        approved:
          approvedChanges,
      },
      key: 'approved',
      id: id
    });
  }
  return (
    <Card className="w-100 p-3">
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <CardHeader>
        <p className="h3 text-capitalize"> {t('approved.approved')} </p>
      </CardHeader>
      <Row className="w-100">
        <div className="form-row">
          {/* checkboxes questions */}
          {Object.keys(checkboxes).map(checkbox => <ToggleCheckbox
            key={checkbox}
            checkboxes={checkboxes}
            data={data}
            handleCheckboxChange={handleCheckboxChange}
            view={view}
            name={checkbox}
            text={t(`approved.${checkbox}`)}
          />)}
          {/* questions */}

          {/* estimated Savings : amount */}
          <Col className="m-5" md="3">
            <p className="h3">{t('approved.Estimated Savings')}</p>
            <label
              className="form-control-label"
              htmlFor="estimatedSavingsAmount"
            >
              {t('approved.Amount')}
            </label>
            <Input
              id="estimatedSavingsAmount"
              name="estimatedSavingsAmount"
              value={
                view
                  ? data[1].estimatedSavings?.amount
                  : estimatedSavings?.amount
              }
              onChange={(e) => {
                setEstimatedSavings({
                  ...estimatedSavings,
                  amount: +e.target.value,
                })
              }}
              type="number"
              min={0}
              disabled={view}
            />
            {/* estimated savings : type */}
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="estimatedSavingsType"
              >
                {t('approved.Type')}
              </label>
              <Input
                id="estimatedSavingsType"
                type="select"
                disabled={view}
                onChange={(e) => {
                  setEstimatedSavings({
                    ...estimatedSavings,
                    type: e.target.value,
                  })
                }
                }
              >
                {estimatedSavingsType.map(el => <option key={el.value} value={el.value}>{t(`approved.${el.label}`)}</option>)}
              </Input>
            </FormGroup>
          </Col>
          {/* approved*/}
          <Col className="m-5" md="4">
            <FormGroup>
              <p className="h3">Approval decision</p>
              <Input
                type="select"
                disabled={view}
                value={approved}
                onChange={(e) => {
                  setDataChanged(true)
                  setApproved(e.target.value)
                }}
              >
                {statusApproval.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
              </Input>
            </FormGroup>
          </Col>
          {/* hold reason*/}
          {(/hold/i).test(approved) && <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.hold reason')}</p>
            <Input
              placeholder={t('evaluated.hold reason')}
              value={holdReason}
              onChange={(e) => setHoldReason(e.target.value)}
              type="text"
            />
          </Col>}
          {/* rejected reason*/}
          {(/false/i).test(approved) && <Col className="m-5" md="4">
            <FormGroup>
              <p className="h3">{t('evaluated.rejected reason')}</p>
              <Input
                type="select"
                disabled={view}
                value={rejectedReason}
                onChange={(e) => {
                  setRejectedReason(e.target.value)
                }}
              >
                {rejectedReasons.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
              </Input>
            </FormGroup>
          </Col>}
          {/* other */}
          {((/false/i).test(approved) && rejectedReason === 'Other') && <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.Other')}</p>
            <Input
              placeholder="Other"
              disabled={view}
              value={other}
              onChange={(e) => setOther(e.target.value)}
              type="text"
            />
          </Col>}
        </div>
      </Row>
      <div className="text-center">
        {!view && <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || view} >
          {t('evaluated.saveChanges')}
        </Button>}
      </div>
    </Card>
  );
};
export default Approved;