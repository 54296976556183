/*eslint-disable*/
import defaultImg from "assets/img/theme/avatar.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import ProfileHeader from "../../../components/Headers/ProfileHeader";
import { editUser } from "../../../store/slices/users/userActions";

function Profile() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const username = register("username", {
    required: "Please enter your Username",
    minLength: {
      value: 4,
      message: "Username must be at least 4 characters",
    },
  });
  const userCode = register("userCode", {
    minLength: {
      value: 4,
      message: "User Code must be at least 4 characters",
    },
  });
  const email = register("email", { required: "This is required" });
  const mobile = register("mobile", {
    required: "Please enter a valid Mobile",
    minLength: {
      value: 11,
      message: "Mobile must be at least 11 numbers",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "Mobile must be numbers only",
    },
  });
  const submitEditForm = async (data) => {
    let myUser = JSON.parse(localStorage.getItem("user"));

    const user = {
      ...data,
      _id: myUser._id,
    };
    myUser = { ...myUser, ...data };
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure?",
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    if (result) {
      localStorage.setItem("user", JSON.stringify(myUser));
      dispatch(editUser(user));
      history.go(0);
    }
  };
  return (
    <>
      <ProfileHeader username={`${t("profile.hello")} ${user.username}`} />
      <Container className="mt--6" fluid>
        <Card className="card-profile">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <img
                  alt="..."
                  className="rounded-circle"
                  src={user.image || defaultImg}
                />
              </div>
            </Col>
          </Row>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">{t("editProfile")}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="pt-0">
            <Form role="form" onSubmit={handleSubmit(submitEditForm)}>
              <h6 className="heading-small text-muted mb-4">
                {t("userInformation")}
              </h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        {t("username")}
                      </label>
                      <Input
                        defaultValue={user.username}
                        id="input-username"
                        placeholder="Username"
                        type="text"
                        name="username"
                        onChange={username.onChange}
                        onBlur={username.onBlur}
                        innerRef={username.ref}
                      />
                      <small className="text-danger">
                        {errors.username && errors.username.message}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        {t("email")}
                      </label>
                      <Input
                        id="input-email"
                        placeholder="jesse@example.com"
                        type="email"
                        name="email"
                        defaultValue={user.email}
                        onChange={email.onChange}
                        onBlur={email.onBlur}
                        innerRef={email.ref}
                      />
                      <small className="text-danger">
                        {errors.email && errors.email.message}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-mobile"
                      >
                        {t("moblie")}
                      </label>
                      <Input
                        defaultValue={user.mobile}
                        id="input-mobile"
                        placeholder="Mobile"
                        type="tel"
                        name="mobile"
                        onChange={mobile.onChange}
                        onBlur={mobile.onBlur}
                        innerRef={mobile.ref}
                      />
                      <small className="text-danger">
                        {errors.moblie && errors.moblie.message}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-user-type"
                      >
                        {t("userType")}
                      </label>
                      <Input
                        defaultValue={user.userType}
                        id="input-user-type"
                        placeholder="First name"
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-userCode"
                      >
                        {t("profile.userCode")}
                      </label>
                      <Input
                        defaultValue={user?.userCode}
                        id="input-userCode"
                        placeholder="User ID"
                        type="text"
                        name="userCode"
                        onChange={userCode.onChange}
                        onBlur={userCode.onBlur}
                        innerRef={userCode.ref}
                      />
                      <small className="text-danger">
                        {errors.userCode && errors.userCode.message}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <Button className="mr-4" color="default" type="submit">
                {t("edit")}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
export default Profile;
