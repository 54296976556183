import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const asyncGetCount = (prefix, url) => {
  return createAsyncThunk(`analytics/${prefix}`, async () => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e;
    }
  });
};
