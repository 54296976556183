/* eslint-disable no-unused-vars */
import classnames from "classnames";
import AuthHeader from "components/Headers/AuthHeader.js";
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { useAppDispatch } from "store/store";
import { notify } from "../../../utils/helpers";
import { loginUser } from "../../../store/slices/auth/authActions";

function Login() {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);
  const notificationAlertRef = useRef(null);

  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const email = register("email", {
    required: "Email is required",
  });
  const password = register("password", {
    required: "Password is required",
  });

  const onSubmit = async ({ email, password }) => {
    try {
      const sentEmail = email.toLowerCase();
      const user = await appDispatch(loginUser({ email: sentEmail, password }));
      if (user.error) {
        notify(
          "danger",
          `${user.payload.message}`,
          notificationAlertRef,
          "Something went wrong"
        );
      } else {
        notify(
          "success",
          `${t("login.welcomeMsg")} ${user.payload.username}!`,
          notificationAlertRef,
          t("login.welcomeLabel")
        );
        setTimeout(() => {
          history.push("/admin/dashboard");
          history.go(0);
        }, 500);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader title={t("welcome")} lead={t("kindlyLogin")} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2 className="text-muted">{t("signIn")}</h2>
                </div>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="email"
                        placeholder="Email"
                        onFocus={() => setFocusedEmail(true)}
                        name={email.name}
                        onChange={email.onChange}
                        onBlur={email.onBlur}
                        innerRef={email.ref}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {errors.email && errors.email.message}
                    </small>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={password.onBlur}
                        onChange={password.onChange}
                        innerRef={password.ref}
                        name={password.name}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {errors.password && errors.password.message}
                    </small>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info">
                      {t("signIn")}
                    </Button>
                  </div>
                </Form>
                <div className="text-center">
                  <a
                    className="text-dark"
                    href={"register"}
                    onClick={() => history.push("/auth/register")}
                  >
                    <h4 className="text-muted">{t("createNewAccount")}</h4>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
