import { createSlice, current } from "@reduxjs/toolkit";
import { addDepartment, deleteDepartment, editDepartment, getDepartments, getDepartmentsById } from "./departmentActions";
const initialState = {
    departments: []
};

export const departmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDepartments.fulfilled, (state, action) => {
            state.departments = action.payload;
        });
        builder.addCase(getDepartmentsById.fulfilled, (state, action) => {
            state.departments = action.payload;
        });
        builder.addCase(addDepartment.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.departments = newState;
        });
        builder.addCase(deleteDepartment.fulfilled, (state, action) => {
            const newState = current(state).departments.filter(
                (department) => department._id !== action.payload
            );
            state.departments = newState;
        });
        builder.addCase(editDepartment.fulfilled, (state, action) => {
            const newState = current(state).departments.map(
                (department) => {
                    if (department._id === action.payload._id) { return (action.payload) } else { return department }
                }
            );
            state.departments = newState;
        });
    },
});

export default departmentSlice.reducer;
