/*eslint-disable*/
// nodejs library that concatenates classes
import defaultImg from "assets/img/theme/avatar.png";
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// reactstrap components
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { logOutUser } from "store/slices/auth/authActions";
import Modal from "../../views/pages/components/Modal/Modal";
import { resetFilterFromLocalStorage } from "../../utils/helpers";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, ...props }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [isVisible, setIsVisible] = useState(false);
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  const logoutUser = () => {
    resetFilterFromLocalStorage();
    dispatch(logOutUser());
  };
  const onChangeLanguageButton = () => {
    setIsVisible(true);
    setTimeout(() => {
      history.push("/");
      history.go(0);
      if (i18n.language === "en") {
        i18n.changeLanguage("ar");
      } else if (i18n.language === "ar") {
        i18n.changeLanguage("en");
      }
      setIsVisible(false);
    }, 1500);
  };
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-dark": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={user.image ? user.image : defaultImg}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user.username.toUpperCase()}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{t("welcome")}!</h6>
                  </DropdownItem>
                  <DropdownItem
                    href={"profile?id=" + user._id}
                    onClick={() => history.push("/admin/profile")}
                  >
                    <i className="ni ni-single-02" />
                    <span className="nav-link-text">{t("myProfile")}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="/"
                    onClick={logoutUser} // logout action
                  >
                    <i className="ni ni-user-run" />
                    <span>{t("logout")}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={onChangeLanguageButton} // logout action
                  >
                    <i className="ni ni-ui-04" />
                    <span>{t("changeLanguage")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal visible={isVisible}>
        <Spinner
          color="light"
          style={{
            height: "5rem",
            width: "5rem",
          }}
        >
          Loading...
        </Spinner>
      </Modal>
    </>
  );
}

AdminNavbar.defaultProps = {
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
