import React from 'react'
import { uri } from "../../../../constants/API_URLS"
import keys20 from "../pics/20keys.png"
import arrowRight from "../pics/arrow-right.svg"
import Kaizen from "../pics/japanesse.png"
import { Table } from 'reactstrap'
import FilesImage from '../../../../components/FilesImage/FilesImage'

function KaizenReport({ cell, ref, row, ideas }) {
    return (
        <div style={{ display: "none" }}>
            <div
                style={{ margin: "100px 40px" }}
                id={cell}
                ref={ref}
            >
                <header>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "30px",
                            alignItems: 'center'
                        }}
                    >
                        <img src={Kaizen} width={100} height={50} />
                        <img src={keys20} width={100} height={50} />
                    </div>
                    <h1
                        style={{
                            textAlign: "center",
                            fontSize: "40px",
                        }}
                    >
                        Key 3 suggestion Implementation Report
                    </h1>
                </header>
                <div style={{ display: 'flex', gap: '10px', marginTop: '30px', alignItems: 'center' }} >
                    {row.operatorImg && <FilesImage path={row.operatorImg} alt='Operator Image' />}
                    <h3>{row.employeeName}</h3>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: "30px",
                        height: "500px",
                    }}
                >
                    <div
                        style={{
                            flex: 5,
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "0px",
                                textAlign: "center",
                            }}
                        >
                            Before
                        </p>
                        <div
                            style={{
                                border: "2px solid black",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={
                                    uri +
                                    "/file/" +
                                    row.status.implemented.before?._id
                                }
                                width="100%"
                                height="100%"
                                alt="Before"
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "50px",
                            margin: "auto 0px",
                        }}
                    >
                        <img src={arrowRight} />
                        <img src={arrowRight} />
                        <img src={arrowRight} />
                    </div>
                    <div
                        style={{
                            flex: 5,
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "0px",
                                textAlign: "center",
                            }}
                        >
                            After
                        </p>
                        <div
                            style={{
                                border: "2px solid black",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={
                                    uri +
                                    "/file/" +
                                    row.status.implemented.after?._id
                                }
                                width="100%"
                                height="100%"
                                alt="After"
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(calc(100% / 3), 1fr))',
                        marginTop: "100px",
                        height: "200px",
                        textAlign: "center",
                    }}
                >
                    <div style={{ width: '100%' }} >
                        <p
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "0px",
                            }}
                        >
                            Idea Text
                        </p>
                        <div
                            style={{
                                border: "2px solid black",
                                height: "100%",
                                padding: "15px",

                            }}
                        >
                            <h2 style={{ wordWrap: "break-word" }}>{row.ideaText}</h2>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}     >
                        <p
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "0px",
                            }}
                        >
                            Effect/Savings
                        </p>
                        <div
                            style={{
                                border: "2px solid black",
                                height: "100%",
                                width: "100%",
                                padding: "15px",
                            }}
                        >
                            {row.effectOnPQCDSM?.map((el) => (
                                <h2 key={el}>{`${el}`}</h2>
                            ))}
                            <h2>
                                Savings: {row.status.implemented.savings}
                            </h2>
                            <h2>
                                ROI: {row.status.implemented.roi}
                            </h2>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "0px",
                            }}
                        >
                            Implemented Team
                        </p>
                        <div
                            style={{
                                border: "2px solid black",
                                height: "100%",
                                width: "100%",
                                padding: "15px",
                            }}
                        >
                            {Array.isArray(row.status?.evaluated?.questions?.responsiblePerson) && <h2 style={{ wordWrap: "break-word" }}>{row.status?.evaluated?.questions?.responsiblePerson?.map((el) => (
                                <h2 key={el}>{`${el}`}</h2>
                            ))}</h2>}
                        </div>
                    </div>
                </div>
                {row.status?.implemented?.oplDescription?.length && <div style={{ marginTop: '100px' }}>
                    <Table className="align-items-center" responsive bordered >
                        <thead>
                            <tr>
                                <th scope="col" >
                                    #
                                </th>
                                <th scope="col" >
                                    KPI(automated)
                                </th>
                                <th scope="col" >
                                    Period
                                </th>
                                <th scope="col" >
                                    Before
                                </th>
                                <th scope="col" >
                                    After
                                </th>
                                <th scope="col" >
                                    Saving
                                </th>
                                <th scope="col" >
                                    Notes
                                </th>
                            </tr>
                        </thead>
                        <tbody className="list">
                            {row.status?.implemented?.oplDescription?.map(oplRow => {
                                return (
                                    <tr key={oplRow?.kpi}>
                                        {Object.keys(oplRow).map((element, index) => {
                                            return (
                                                <td key={element + index} >{oplRow[element]}</td>
                                            )
                                        })}

                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>}
            </div>
        </div>
    )
}

export default KaizenReport