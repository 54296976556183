import { createSlice, current } from "@reduxjs/toolkit";
import { addFactory, deleteFactory, editFactory, getFactories, getFactoriesById } from "./factoryActions";
const initialState = {
    factories: []
};

export const factorySlice = createSlice({
    name: "factory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFactories.fulfilled, (state, action) => {
            state.factories = action.payload;
        });
        builder.addCase(getFactoriesById.fulfilled, (state, action) => {
            state.factories = action.payload;
        });
        builder.addCase(addFactory.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.factories = newState;
        });
        builder.addCase(deleteFactory.fulfilled, (state, action) => {
            const newState = current(state).factories.filter(
                (factory) => factory._id !== action.payload
            );
            state.factories = newState;
        });
        builder.addCase(editFactory.fulfilled, (state, action) => {

            const newState = current(state).factories.map(
                (factory) => {
                    if (factory._id === action.payload._id) { return (action.payload) } else { return factory }
                }
            );
            state.factories = newState;
        });
    },
});

export default factorySlice.reducer;
