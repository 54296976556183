import { createSlice } from "@reduxjs/toolkit";
import {
  generateRefreshToken,
  loginUser,
  logOutAction,
  signupUser,
} from "./authActions";

const user =
  typeof window !== "undefined" ? JSON.parse(localStorage.getItem("user")) : {};
const token =
  typeof window !== "undefined"
    ? localStorage.getItem("opexDashbaordToken")
    : null;

const initialState = user
  ? { isLoggedIn: true, user, token }
  : { isLoggedIn: false, user: {}, token: "" };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.token = action.payload.token;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
    });
    builder.addCase(logOutAction, (state, action) => {
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
    });
    builder.addCase(signupUser, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    });
    builder.addCase(generateRefreshToken.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.token = action.payload.token;
    });
    builder.addCase(generateRefreshToken.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";
    });
  },
});

// Action creators are generated for each case reducer function

export default authSlice.reducer;
