import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoutes = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !(
        localStorage.getItem("opexDashbaordToken") &&
        localStorage.getItem("user")
      ) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/LandingPage" />
      )
    }
  />
);

export default AuthRoutes;
