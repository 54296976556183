import { createSlice, current } from "@reduxjs/toolkit";
import { setLoading } from "./sharedActions";

const initialState = {
  isLoading: false,
};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const { setIsLoading } = sharedSlice.actions;
export default sharedSlice.reducer;
