import { createSlice, current } from "@reduxjs/toolkit";
import { addStage, deleteStage, editStage, getStages, getStagesById } from "./stageActions";
const initialState = {
    stages: []
};

export const stageSlice = createSlice({
    name: "stage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStages.fulfilled, (state, action) => {
            state.stages = action.payload;
        });
        builder.addCase(getStagesById.fulfilled, (state, action) => {
            state.stages = action.payload;
        });
        builder.addCase(addStage.fulfilled, (state, action) => {
            const newState = current(state).push(action.payload);
            state.stages = newState;
        });
        builder.addCase(deleteStage.fulfilled, (state, action) => {
            const newState = current(state).stages.filter(
                (stage) => stage._id !== action.payload
            );
            state.stages = newState;
        });
        builder.addCase(editStage.fulfilled, (state, action) => {
            const newState = current(state).stages.map(
                (stage) => {
                    if (stage._id === action.payload._id) { return (action.payload) } else { return stage }
                }
            );
            state.stages = newState;
        });
    },
});

export default stageSlice.reducer;
