import { createSlice, current } from "@reduxjs/toolkit";
import {
  addAICMinibusiness,
  deleteAICMinibusiness,
  getAICMinibusinesses,
  editAICMinibusiness,
  getAICMinibusinessesById,
} from "./AICMinibusinessActions";
const initialState = {
  AICMinibusinesses: [],
};

export const AICMinibusinessSlice = createSlice({
  name: "AICMinibusiness",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAICMinibusinesses.fulfilled, (state, action) => {
      state.AICMinibusinesses = action.payload;
    });
    builder.addCase(getAICMinibusinessesById.fulfilled, (state, action) => {
      state.AICMinibusinesses = action.payload;
    });
    builder.addCase(addAICMinibusiness.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.AICMinibusinesses = newState;
    });
    builder.addCase(deleteAICMinibusiness.fulfilled, (state, action) => {
      const newState = current(state).AICMinibusinesses.filter(
        (AICMinibusiness) => AICMinibusiness._id !== action.payload
      );
      state.AICMinibusinesses = newState;
    });
    builder.addCase(editAICMinibusiness.fulfilled, (state, action) => {
      const newState = current(state).AICMinibusinesses.map(
        (AICMinibusiness) => {
          if (AICMinibusiness._id === action.payload._id) {
            return action.payload;
          } else {
            return AICMinibusiness;
          }
        }
      );
      state.AICMinibusinesses = newState;
    });
  },
});

export default AICMinibusinessSlice.reducer;
