import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import FilesImage from "../../../components/FilesImage/FilesImage";
import { getFilteredIdeas, getIdeas } from "../../../store/slices/idea/ideaActions";
import { kaizenTemplateTableColumns, replicationSelectValues, statusFilter } from "../../../utils/Data";
import KaizenReport from "./KaizenReport/KaizenReport";
import OPLReport from "./OPLReport/OPLTemplate";
import { animatedComponents, deleteZeroProps, filterValuesInIdeasFilter } from "../../../utils/helpers";
import Select from "react-select";
import { getCompanies } from "../../../store/slices/company/companyActions";
import { getFactories } from "../../../store/slices/factory/factoryActions";
import { getMiniBusinesses } from "../../../store/slices/mini-business/miniBusinessActions";
import { getDepartments } from "../../../store/slices/department/departmentActions";
import { getStages } from "../../../store/slices/stage/stageActions";
import { getFirstCuts } from "../../../store/slices/firstCut/firstCutActions";
import { getSecondCuts } from "../../../store/slices/secondCut/secondCutActions";
import { getBusinessUnits } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import { getareaSections } from "../../../store/slices/areaSection/areaSectionActions";
import { getAICMinibusinesses } from "../../../store/slices/AICMinibusiness/AICMinibusinessActions";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const KaizenTemplate = () => {
  const { t, i18n } = useTranslation();
  let ref = useRef({});
  let OPlRef = useRef({})
  const ideas = useSelector((state) => state.idea.ideas);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const dispatch = useDispatch();
  const [selectedIdeaFilter, setSelectedIdeaFilter] = useState({});
  const factoryBusinessUnits = useSelector(
    (state) => state?.factoryBusinessUnit?.businessUnits
  ) || [];
  const areaSections = useSelector((state) => state?.areaSection?.areaSections) || [];
  const aicMinibuisnesses = useSelector((state) => state?.AICMinibusiness?.AICMinibusinesses) || [];

  const updateFilteredData = (selectIdeaFilter) => {
    const newSelectedFilter = {
      ...JSON.parse(JSON.stringify(selectedIdeaFilter)),
      ...selectIdeaFilter,
    };
    setSelectedIdeaFilter(newSelectedFilter);
  };

  const filterBtnHandler = () => {
    let filterObject = {};
    for (let prop in selectedIdeaFilter) {
      if (prop !== "replication" && prop !== "status") {
        filterObject[prop] = {
          $in: selectedIdeaFilter[prop].map((el) => el.value),
        };
      }
      if (prop === "replication") {
        filterObject["status.replicated.type"] = {
          $in: selectedIdeaFilter[prop].map((el) => el.value),
        };
      }
      if (prop === "status") {
        selectedIdeaFilter[prop]
          .map((el) => el.value)
          .forEach((item) => {
            filterObject[`status.${item}.value`] = true;
          });
      }
      deleteZeroProps(filterObject);
    }
    dispatch(getFilteredIdeas(filterObject));
  };
  useEffect(() => {
    dispatch(getIdeas());
    dispatch(getBusinessUnits());
    dispatch(getareaSections());
    dispatch(getAICMinibusinesses());
  }, []);

  useEffect(() => {
    if (ideas?.length)
      setFilteredIdeas(
        ideas?.filter((idea) => idea.status?.implemented?.value === true)
      );
  }, [ideas]);


  return (
    <>
      <SimpleHeader name="Idea Bank" parentName="Kaizen Template" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Kaizen Templates</h3>
              </CardHeader>
              <ToolkitProvider
                data={filteredIdeas}
                keyField="_id"
                columns={[
                  ...kaizenTemplateTableColumns(i18n.language, t),
                  {
                    dataField: "status",
                    text: t("idea.imageBefore"),
                    formatter: (cell) => cell?.implemented?.before?._id && <FilesImage path={cell?.implemented?.before?._id} alt="Image Before" />,
                  },
                  {
                    dataField: "status",
                    text: t("idea.imageAfter"),
                    formatter: (cell) => cell?.implemented?.after?._id && <FilesImage path={cell?.implemented?.after?._id} alt="Image After" />,
                  },
                  {
                    dataField: "_id",
                    text: "Generate kaizen journal",
                    formatter: (cell, row) => (
                      <>
                        <div>
                          <ReactToPrint content={() => ref.current}>
                            <PrintContextConsumer>
                              {({ handlePrint }) => {
                                const hanldeClick = () => {
                                  const currentDiv =
                                    document.getElementById(cell);
                                  ref.current = currentDiv;
                                  handlePrint();
                                };
                                return (
                                  <>
                                    <Button
                                      size="sm"
                                      type="button"
                                      color="info"
                                      onClick={hanldeClick}
                                    >
                                      <span className="text-capitalize">
                                        Print
                                      </span>
                                    </Button>
                                  </>
                                );
                              }}
                            </PrintContextConsumer>
                          </ReactToPrint>
                          <KaizenReport cell={cell} ideas={ideas} ref={ref} row={row} />
                        </div>
                      </>
                    ),
                  },
                  {
                    dataField: "_id",
                    text: "Generate Knowledge report",
                    formatter: (cell, row) => (
                      <>
                        <div>
                          <ReactToPrint content={() => OPlRef.current}>
                            <PrintContextConsumer>
                              {({ handlePrint }) => {
                                const hanldeClick = () => {
                                  const currentDiv =
                                    document.getElementById(cell + 1);
                                  OPlRef.current = currentDiv;
                                  handlePrint();
                                };
                                return (
                                  <>
                                    <Button
                                      size="sm"
                                      type="button"
                                      color="info"
                                      onClick={hanldeClick}
                                    >
                                      <span className="text-capitalize">
                                        Print
                                      </span>
                                    </Button>
                                  </>
                                );
                              }}
                            </PrintContextConsumer>
                          </ReactToPrint>
                          <OPLReport cell={cell} ideas={ideas} ref={OPlRef} row={row} />
                        </div>
                      </>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                      <div style={{ display: "flex", gap: "50px" }}>
                        {/* filter by status */}
                        <Col className="mb-3" md="3" xs="12">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                          >
                            {t('idea.filterByIdeaStatus')}
                          </label>
                          <Select
                            id="exampleFormControlSelect3"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={(value) => {
                              updateFilteredData({ status: value });
                            }}
                            options={statusFilter}
                          />
                        </Col>
                        <Col className="mb-3" md='3' xs='12'>
                          <label
                            className="form-control-label"
                            htmlFor="replicationStatus"
                          >
                            {t('idea.filterByReplication')}
                          </label>
                          <Select
                            id="replicationStatus"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={(value) => {
                              updateFilteredData({ replication: value });
                            }}
                            options={replicationSelectValues}
                          />
                        </Col>
                        {/* filter by factoryBusinessUnit */}
                        <Col className="mb-3" md="3" xs="12">
                          <label
                            className="form-control-label"
                            htmlFor="filterByfactoryBusinessUnit"
                          >
                            {t('idea.FilterByFactoryBusinessUnit')}
                          </label>
                          <Select
                            id="filterByfactoryBusinessUnit"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={(value) => {
                              updateFilteredData({ factoryBusinessUnit: value });
                            }}
                            options={factoryBusinessUnits?.map((el) => {
                              return {
                                value: el._id,
                                label: el.name[i18n.language],
                              };
                            })}
                          />
                          {/* filter by areaSection */}
                        </Col>
                        <Col className="mb-3" md="3" xs="12">

                          <label
                            className="form-control-label"
                            htmlFor="filterByAreaSection"
                          >
                            {t('idea.filterByAreaSection')}
                          </label>
                          <Select
                            id="filterByAreaSection"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={(value) => {
                              updateFilteredData({ areaSection: value });
                            }}
                            options={filterValuesInIdeasFilter(areaSections, 'factoryBusinessUnit', selectedIdeaFilter)?.map((el) => {
                              return {
                                value: el._id,
                                label: el.name[i18n.language],
                              };
                            })}
                          />
                        </Col>
                        {/* filter by aicminibusiness */}
                        {/* <Col className="mb-3" md="3" xs="12">
                          <label
                            className="form-control-label"
                            htmlFor="filterByAICMinibusiness"
                          >
                            {t('idea.filterByMiniBusiness')}
                          </label>
                          <Select
                            id="filterByAICMinibusiness"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={(value) => {
                              updateFilteredData({ AICMinibusiness: value });
                            }}
                            options={filterValuesInIdeasFilter(aicMinibuisnesses, 'areaSection', selectedIdeaFilter)?.map((el) => {
                              return {
                                value: el._id,
                                label: el.name[i18n.language],
                              };
                            })}
                          />
                        </Col> */}
                      </div>
                      <div>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "150px", marginBottom: "10px" }}
                          onClick={filterBtnHandler}
                        >
                          {t('idea.filter')}
                        </Button>
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      hover={true}
                      tabIndexCell={true}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      keyField="index"
                      data={filteredIdeas?.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default KaizenTemplate;
