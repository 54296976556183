import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";
import { deleteUser, editUser, getUsers, getUsersCode } from "./userActions";

export const fetchUserBytoken = createAsyncThunk(
  "users/fetchUserByToken",
  async ({ token }, thunkAPI) => {
    try {
      const response = await axios(API_URLS.User.token, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      let data = JSON.stringify(response);

      if (response?.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return e;
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  usersCode: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    updateUser: (state, action) => {
      state = action.payload;
    },
    addUserFromToken: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getUsersCode.fulfilled, (state, action) => {
      state.usersCode = action.payload;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const newState = current(state).users.filter(
        (user) => user._id !== action.payload
      );
      state.users = newState;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {});
  },
});

// Action creators are generated for each case reducer function
export const { getUser, updateUser, addUserFromToken } = userSlice.actions;

export default userSlice.reducer;
