import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getMiniBusinesses = createAsyncThunk(
    "miniBusiness/all",
    async () => {
        try {
            const response = await axios.get(API_URLS.MiniBusiness.get);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const addMiniBusinesses = createAsyncThunk(
    "miniBusiness/post",
    async (data) => {
        try {
            const token = localStorage.getItem("opexDashbaordToken");
            let requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await axios.post(API_URLS.MiniBusiness.post, data, requestOptions);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const editMiniBusinesses = createAsyncThunk("miniBusiness/edit", async (data) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        let requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.MiniBusiness.put.replace("{Id}", data._id);
        await axios.put(url, data, requestOptions);
        return data;
    } catch (e) {
        return e;
    }
});

const deleteMiniBusinesses = createAsyncThunk("miniBusiness/delete", async (id) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.MiniBusiness.delete.replace("{Id}", id);
        await axios.delete(url, requestOptions);
        return id;
    } catch (e) {
        return e;
    }
});

export { addMiniBusinesses, deleteMiniBusinesses, editMiniBusinesses, getMiniBusinesses };
