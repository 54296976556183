import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const questionsSlice = createSlice({
  initialState,
  name: "questionsList",
  reducers: {


  },
});
