import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { useDispatch, useSelector, } from "react-redux";
import { Button, Card, CardBody, CardHeader, Container, Progress, Row } from "reactstrap";
import { addVideo, getVideos } from "../../../store/slices/videos/videosActions";
import { notify } from "../../../utils/helpers";
import style from "./UploadVideo.module.scss";
import VideosTable from "../../../components/VideosTable/VideosTable";

const UploadVideo = () => {
    const [isUploading, setIsUploading] = useState(false);
    const { t } = useTranslation();
    const notificationAlertRef = useRef(null);
    const videos = useSelector(
        (state) => state?.videos?.videos
    );
    const videoPercent = useSelector(
        (state) => state?.videos?.videoPercent
    );
    const dispatch = useDispatch();
    const msgAlert = (type, msg) => {
        notify(type, msg, notificationAlertRef);
    }

    const [file, setFile] = useState(null);

    const onUpload = async () => {
        if (file) {
            setIsUploading(true)
            dispatch(addVideo({ file, alertFun: msgAlert, t }))
        }
    };
    useEffect(() => {
        dispatch(getVideos());
    }, []);

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name='Video' parentName={t("idea.ideasManagement")} />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{t("r&r.uploadYourFile")}</h3>
                            </CardHeader>
                            <CardBody className={style.cardBody}>
                                <Row className={style.uploadContainer}>
                                    <input
                                        type="file"
                                        disabled={isUploading}
                                        accept="video/mp4,video/x-m4v,video/*"
                                        onChange={(e) => setFile(e.target.files[0])} />
                                    {!isUploading && <Button type="button" color="primary" onClick={onUpload} disabled={!file}>{t("r&r.upload")}</Button>}
                                    {isUploading && <Progress
                                        style={{ height: '18px' }}
                                        className="my-2 w-50"
                                        animated
                                        color={videoPercent === 100 ? 'success' : null}
                                        value={videoPercent}
                                    >
                                        {`${videoPercent}%`}
                                    </Progress>}
                                </Row>
                                <Row >
                                    <VideosTable videos={videos} />
                                </Row>
                            </CardBody>
                        </Card>
                    </div >
                </Row >
            </Container >
        </>
    );
};
export default UploadVideo;