import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { getRelicationColor, getStatusColor } from '../../utils/helpers';

function IdeaStatusElement({ idea }) {
    const { t } = useTranslation();
    return (
        <div className='d-flex flex-column'>
            {Object.entries(idea).map((el) => <Button
                key={el[0]}
                size="sm"
                type="button"
                style={{ width: '180px', margin: '2px' }}
                color={el[0] === "replicated" ? getRelicationColor(el[1].type) : getStatusColor(el[1]?.accepted)}
            >
                <span className>
                    {t(`idea.${el[0] === "replicated" ? el[1]?.type || "Replicated" : el[0]}`)}
                </span>
            </Button>
            )}
        </div>
    )
}

export default IdeaStatusElement