import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { cloneObj, countTrueProps } from "../../../shared/utils";
import { addScore } from "../../../store/slices/idea/ideaActions";
import { useTranslation } from "react-i18next";
const AddScore = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { employee } = location.state;
  const id = useParams().id;
  const [checkboxes, setCheckboxes] = useState({
    isItCheap: false,
    isItEasyToImplement: false,
    canItBeImplementedWithin3Weeks: false,
    isItOneOfOurTop3FavSuggestions: false,
    willItSaveAsignificantAmout: false,
    isItUrgent: false,
    canWeImplementItOurselves: false,
  });
  const [totalTicks, setTotalTicks] = useState(0);
  const [priority, setPriority] = useState("Low");
  const [dueDate, setDueDate] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const handleCheckboxChange = (e, key) => {
    const checkboxesClone = cloneObj(checkboxes);
    checkboxesClone[key] = e.target.checked;
    const count = countTrueProps(checkboxesClone);
    setTotalTicks(count);
    setPriority(count >= 4 ? "High" : "Low");
    setCheckboxes(checkboxesClone);
  };
  const handleSubmit = async () => {
    try {
      let result = await confirm({
        title: <>Confirm</>,
        message: t("Are you sure you want to submit this?"),
        confirmText: "Confirm",
        confirmColor: "primary",
        cancelColor: "link text-danger",
      });
      const score = {
        ...checkboxes,
        idea: id,
        suggestedBy: employee,
        totalTicks,
        priority,
        dueDate,
        responsiblePerson,
      };
      if (result) {
        dispatch(addScore(score));
        history.push("/admin/idea-score");
        history.go(0);
      }
    } catch (e) {
      return e;
    }
  };
  return (
    <>
      <SimpleHeader name="Add Score" parentName="Idea management" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Add Score</h3>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <div className="form-row">
                      {/* Is it cheap */}
                      <Col className="m-5" md="3">
                        <p className="h3">Is it cheap</p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="isItCheap"
                            value={checkboxes.isItCheap}
                            onChange={(e) =>
                              handleCheckboxChange(e, "isItCheap")
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Is it easy to implement */}
                      <Col className="m-5" md="3">
                        <p className="h3">Is it easy to implement</p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="isItEasyToImplement"
                            value={checkboxes.isItEasyToImplement}
                            onChange={(e) =>
                              handleCheckboxChange(e, "isItEasyToImplement")
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Can we implement it ourselves */}
                      <Col className="m-5" md="3">
                        <p className="h3">Can we implement it ourselves</p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="canWeImplementItOurselves"
                            value={checkboxes.canWeImplementItOurselves}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "canWeImplementItOurselves"
                              )
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Can it be implemented within 3 weeks */}
                      <Col className="m-5" md="3">
                        <p className="h3">
                          Can it be implemented within 3 weeks
                        </p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="canItBeImplementedWithin3Weeks"
                            value={checkboxes.canItBeImplementedWithin3Weeks}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "canItBeImplementedWithin3Weeks"
                              )
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Is it one of our top 3 favorite suggestions */}
                      <Col className="m-5" md="3">
                        <p className="h3">
                          Is it one of our top 3 favorite suggestions
                        </p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="isItOneOfOurTop3FavSuggestions"
                            value={checkboxes.isItOneOfOurTop3FavSuggestions}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "isItOneOfOurTop3FavSuggestions"
                              )
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Will it save a significat amount */}
                      <Col className="m-5" md="3">
                        <p className="h3">Will it save a significat amount</p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="willItSaveAsignificantAmout"
                            value={checkboxes.willItSaveAsignificantAmout}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "willItSaveAsignificantAmout"
                              )
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Is it urgent */}
                      <Col className="m-5" md="3">
                        <p className="h3">Is it urgent</p>
                        <label className="custom-toggle custom-toggle-default mr-1">
                          <input
                            name="isItUrgent"
                            value={checkboxes.isItUrgent}
                            onChange={(e) =>
                              handleCheckboxChange(e, "isItUrgent")
                            }
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                      {/* Due date */}
                      <Col className="m-5" md="3">
                        <p className="h3">Due date</p>
                        <Input
                          defaultValue={new Date().getFullYear() + "-11-23"}
                          type="date"
                          onChange={(e) => setDueDate(e.target.value)}
                        />
                      </Col>
                      {/* Responsible person */}
                      <Col className="m-5" md="3">
                        <p className="h3">Responsible person</p>
                        <Input
                          placeholder="Responsible person"
                          value={responsiblePerson}
                          onChange={(e) => setResponsiblePerson(e.target.value)}
                          type="text"
                          required
                        />
                      </Col>
                    </div>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      Add
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddScore;
