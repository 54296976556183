import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getStages = createAsyncThunk(
    "stage/all",
    async () => {
        try {
            const response = await axios.get(API_URLS.Stage.get);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const getStagesById = createAsyncThunk(
    "stage/all/Id",
    async (id) => {
        try {
            const response = await axios.get(API_URLS.Stage.getAllById.replace('{Id}', id));
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const addStage = createAsyncThunk(
    "stage/post",
    async (data) => {
        try {
            const token = localStorage.getItem("opexDashbaordToken");
            let requestOptions = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await axios.post(API_URLS.Stage.post, data, requestOptions);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);
const editStage = createAsyncThunk("stage/edit", async (data) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        let requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Stage.put.replace("{Id}", data._id);
        await axios.put(url, data, requestOptions);
        return data;
    } catch (e) {
        return e;
    }
});

const deleteStage = createAsyncThunk("stage/delete", async (id) => {
    try {
        const token = localStorage.getItem("opexDashbaordToken");
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const url = API_URLS.Stage.delete.replace("{Id}", id);
        await axios.delete(url, requestOptions);
        return id;
    } catch (e) {
        return e;
    }
});

export { addStage, deleteStage, getStages, editStage, getStagesById };
