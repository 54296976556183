/*eslint-disable*/
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

function ProfileHeader({ username }) {
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage:
            'url("' +
            require("assets/img/theme/clay-banks-u27Rrbs9Dwc-unsplash.jpg")
              .default +
            '")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        +
        <span className="mask bg-gradient-info opacity-1" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-white">{username}</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
