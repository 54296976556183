/*eslint-disable*/
// javascipt plugin for creating charts
//2.11.1
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

function Dashboard() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            {/* <Card>
              <CardHeader>
                <h5 className="h3 mb-0">{t("ideas")}</h5>
              </CardHeader>
              <CardBody>
                <div className="chart" style={{ height: "fit-content" }}>
                  <iframe
                    title="Dashboard - Page 1"
                    width="100%"
                    height="600"
                    src="https://app.powerbi.com/view?r=eyJrIjoiYWI0ZTBmMTQtYWM5ZC00ZmY0LWFhNGItZDc2ZDQ1ZDI5NTc2IiwidCI6IjhkN2UyMGExLTY2YTEtNDE0Yy05YzNjLTZkN2ZlMzA3NzJiYiIsImMiOjh9"
                    frameBorder="0"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
