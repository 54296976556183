import { forwardRef } from "react";
import { Button } from "reactstrap";


const CustomInputForDatePicker = forwardRef(({ value, onClick }, ref) => (
    <Button
        color="secondary"
        type="button"
        size='lg'
        onClick={onClick}
        ref={ref}>
        {value}
    </Button>
));
export default CustomInputForDatePicker;