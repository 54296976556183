import { CheckLg, XCircle } from 'react-bootstrap-icons'
import Logo from "../../../../assets/img/brand/Unistar logo.png"
import { uri } from "../../../../constants/API_URLS"
import { effectOnPQCDSMArray } from '../../../../utils/Data'
import './OPLTemplate.css'

function OPLReport({ cell, ref, row }) {
    return (
        <div style={{ display: "none" }}>
            <div
                style={{ margin: "20px 40px" }}
                id={cell + 1}
                ref={ref}
            >
                <header>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: "15px",
                    }}>
                        <div>
                            <div
                                style={{
                                    margin: "30px 0",
                                }}
                            >
                                <img src={Logo} width={400} height={200} alt='Logo' />
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <h1
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: '700'
                                    }}
                                >
                                    {`Single Point Lesson: ${row?._id ?? ''}`}
                                </h1>
                                <h1
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: '700'
                                    }}
                                >
                                    {`Equipment Name: ${row.areaSection?.name?.en ?? ''}`}

                                </h1>
                            </div>
                        </div>
                        <div >
                            <div style={{
                                width: '200px', height: '300px', margin: 'auto', display: 'grid',
                                gridTemplateColumns: 'repeat(6, 1fr);'
                            }}>
                                {effectOnPQCDSMArray.map(effect => {
                                    return (
                                        <div className='PQDSM-column' key={effect.id}>
                                            <div className='PQDSM-column-left'>{
                                                row.effectOnPQCDSM.includes(effect.text.en) ? <CheckLg color='green' /> : <XCircle color='red' />
                                            }
                                            </div>
                                            <div className='PQDSM-column-right'>
                                                <h3>{effect.text.en}</h3>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </header>

                <div style={{
                    border: '1px solid black',
                    padding: '30px 15px 100px 15px'
                }}>
                    <h1
                        style={{
                            fontSize: "40px",
                            fontWeight: '700',
                            textAlign: 'center'
                        }}
                    >
                        Visual checks and accessible PM's
                    </h1>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "30px",
                            height: "500px",
                        }}
                    >
                        <div
                            style={{
                                flex: 5,
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: '#0046A9',
                                    fontSize: "30px",
                                    marginBottom: "0px",
                                    textAlign: "center",
                                }}
                            >
                                BEFORE
                            </p>
                            <div
                                style={{
                                    border: "2px solid black",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <img
                                    src={
                                        uri +
                                        "/file/" +
                                        row.status.implemented.before?._id
                                    }
                                    width="100%"
                                    height="100%"
                                    alt="Before"
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "50px",
                                margin: "auto 0px",
                            }}
                        >
                        </div>
                        <div
                            style={{
                                flex: 5,
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: '#0046A9',
                                    fontSize: "30px",
                                    marginBottom: "0px",
                                    textAlign: "center",
                                }}
                            >
                                AFTER
                            </p>
                            <div
                                style={{
                                    border: "2px solid black",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <img
                                    src={
                                        uri +
                                        "/file/" +
                                        row.status.implemented.after?._id
                                    }
                                    width="100%"
                                    height="100%"
                                    alt="After"
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px', padding: '20px',
                        marginTop: '50px',
                        fontWeight: '700'
                    }}>
                        <h2 >{row.ideaText}</h2>
                        <h2 >{row.status?.implemented?.ideaText}</h2>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default OPLReport