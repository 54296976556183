import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";
import { setIsLoading } from "../shared/sharedSlice";

const getBusinessUnitNames = createAsyncThunk(
  "businessUnit/BusinessUnit",
  async () => {
    try {
      const response = await axios.get(API_URLS.FactoryBusinessUnit.getNames);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getBusinessUnits = createAsyncThunk("businessUnit/all", async () => {
  try {
    const response = await axios.get(API_URLS.FactoryBusinessUnit.get);
    response.data.shift();
    return response.data;
  } catch (error) {
    return error;
  }
});

// export const getBusinessUnitsIs = createAction(
//   "user/getBusinessUnitsIs",
//   async (dispatch) => {
//     await dispatch(getBusinessUnits());
//     await dispatch(setIsLoading(true));
//   }
// );

const addBusinessUnit = createAsyncThunk("businessUnit/post", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await axios.post(
      API_URLS.FactoryBusinessUnit.post,
      data,
      requestOptions
    );
    return response.data;
  } catch (error) {
    return error;
  }
});
const editBusinessUnit = createAsyncThunk(
  "businessUnit/editBusinessUnit",
  async (data) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.FactoryBusinessUnit.put.replace("{Id}", data._id);
      await axios.put(url, data, requestOptions);
      return data;
    } catch (e) {
      return e;
    }
  }
);

const deleteBusinessUnit = createAsyncThunk(
  "BusinessUnit/delete",
  async (id) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const url = API_URLS.FactoryBusinessUnit.delete.replace("{Id}", id);
      await axios.delete(url, requestOptions);
      return id;
    } catch (e) {
      return e;
    }
  }
);

export {
  getBusinessUnits,
  getBusinessUnitNames,
  addBusinessUnit,
  deleteBusinessUnit,
  editBusinessUnit,
};
