
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import IdeaStatusViewer from "../../../components/IdeaStatusViewer/IdeaStatusViewer";
import { getIdeasByemployeeCode } from "../../../store/slices/idea/ideaActions";
import { ideaStatusType } from "../../../utils/Data";
import { notify } from "../../../utils/helpers";
import style from "./IdeaStatus.module.scss";

export default function IdeaStatus() {
  const notificationAlertRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ideas = useSelector((state) => state.idea.ideasByCode);
  const [employeeId, setEmployeeId] = useState('');
  const [employeeType, setEmployeeType] = useState('Employee ID');

  function msgAlert(type, msg) {
    notify(type, msg, notificationAlertRef, t(""));
  }
  const getEmployeeIdeas = async () => {
    const response = await dispatch(getIdeasByemployeeCode({ employeeCode: employeeId, type: employeeType }));
    console.log(response);
    if (response?.payload?.status === 404 || response?.payload[0] === '') {
      msgAlert('danger', t(response?.payload?.message));
    } else if (response.payload.length || response?.payload?.message !== 'Request failed with status code 500') {
      msgAlert('success', t('Ideas fetched successfully'));
    } else {
      if (employeeType === 'Employee ID') {
        msgAlert('success', t('Ideas fetched successfully but no submitted ideas'));
      } else if (response?.payload?.message === 'Request failed with status code 500') {
        msgAlert('danger', t('No idea with this ID'));
      }
    }
  }
  const onSelectChange = e => {
    setEmployeeType(e.target.value);
  }



  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className={style.pageContainer}>
        <Container className="pt-6">
          <Row className="justify-content-center">
            <Col xs="12">
              <Card className="bg-secondary border-0 p-0">
                <CardHeader>
                  <Row xs="10" md="8">
                    <h3 className="form-control-label">
                      {t("ideaStatus.selectType")}
                    </h3>
                    <Select2
                      value={employeeType}
                      options={{
                        placeholder: t("form.Choose"),
                      }}
                      data-minimum-results-for-search="Infinity"
                      data={ideaStatusType?.map((el) => {
                        return {
                          id: el.id,
                          value: el.id,
                          text: t(`ideaStatus.${el.id}`),
                        };
                      })}
                      onChange={onSelectChange}
                    />
                  </Row>
                </CardHeader>
                <CardBody className={style.cardBody}>
                  <Input type="text" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
                  <Button type="button" color="primary" onClick={getEmployeeIdeas} disabled={!employeeId.length}>{t("submit")}</Button>
                </CardBody>
              </Card>
              {ideas?.length && ideas[0] !== '' ? <IdeaStatusViewer ideasStatus={ideas} /> : null}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
